import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../layout/header";
import Footer from "../layout/footer";

const aboutbg = require("./../../assets/images/background/image-11.jpg");

class ForForeignOwners extends Component {
  ourService = [
    {
      label: "Subsidiary Company",
      index: 0,
      discription:
        "<p>Indian Subsidiary Company</p><p>A foreign company can set up a Private Limited Company as its subsidiary in India. The minimum requirements for incorporating a company in India are&nbsp;</p><p>It should have a minimum of 2 directors and 2 shareholders. One of directors should be an Indian resident. Finding an Indian resident director becomes a challenge for many foreign companies. Generally, the companies appoint the senior employee or the country manager as its director.</p><p>The incorporation documents including the address proof, identity proof, etc of the foreign shareholders and directors to be notarized and Apostilled/ endorsed at the Indian Consulate in the country where the registered office of the entity is situated</p>",
    },
    {
      label: "Branch / Liaison Office",
      index: 1,
      discription: "",
    },
  ];

  renderService = () => {
    return this.ourService.map((item, index) => {
      return (
        <div className="col-lg-6" key={index}>
          <div className="icon-box pl-0">
            {/* <div className="icon">
              <img
                src={require("../../assets/images/icons/icon-3.png")}
                alt=""
              />
            </div> */}
            <h2>{item.label}</h2>
            <div className="text">
              <div dangerouslySetInnerHTML={{ __html: item.discription }} />
            </div>
          </div>
        </div>
      );
    });
  };
  render() {
    return (
      <>
        <Header />
        <div id="search-popup" className="search-popup">
          <div className="close-search theme-btn">
            <span className="flaticon-cancel"></span>
          </div>
          <div className="popup-inner">
            <div className="overlay-layer"></div>
            <div className="search-form">
              <form
                method="post"
                action="http://azim.commonsupport.com/Finandox/index.html"
              >
                <div className="form-group">
                  <fieldset>
                    <input
                      type="search"
                      className="form-control"
                      name="search-input"
                      value=""
                      placeholder="Search Here"
                      required
                    />
                    <input
                      type="submit"
                      value="Search Now!"
                      className="theme-btn"
                    />
                  </fieldset>
                </div>
              </form>
              <br />
              <h3>Recent Search Keywords</h3>
              <ul className="recent-searches">
                <li>
                  <Link to={"/#"}>Finance</Link>
                </li>
                <li>
                  <Link to={"/#"}>Idea</Link>
                </li>
                <li>
                  <Link to={"/#"}>Service</Link>
                </li>
                <li>
                  <Link to={"/#"}>Growth</Link>
                </li>
                <li>
                  <Link to={"/#"}>Plan</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <section className="page-banner">
          <div
            className="page-banner-bg"
            style={{ backgroundImage: "url(" + aboutbg + ")" }}
          ></div>
          <div className="bottom-rotten-curve alternate"></div>

          <div className="auto-container">
            <h1> For Foreign Owner</h1>
            <ul className="bread-crumb clearfix">
              <li>
                <Link to={"/"}>Home</Link>
              </li>
              <li className="active">For Foreign Owner</li>
            </ul>
          </div>
        </section>
        <section className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <div
                className="my-3"
                dangerouslySetInnerHTML={{
                  __html:
                    "<p><h3>Indian Subsidiary Company</h3></p><p>A foreign company can set up a Private Limited Company as its subsidiary in India. The minimum requirements for incorporating a company in India are&nbsp;</p><p>It should have a minimum of 2 directors and 2 shareholders. One of directors should be an Indian resident. Finding an Indian resident director becomes a challenge for many foreign companies. Generally, the companies appoint the senior employee or the country manager as its director.</p><p>The incorporation documents including the address proof, identity proof, etc of the foreign shareholders and directors to be notarized and Apostilled/ endorsed at the Indian Consulate in the country where the registered office of the entity is situated</p>",
                }}
              />
              ;
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}
export default ForForeignOwners;
