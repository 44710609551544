import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header'
import Footer from '../layout/footer'

const aboutbg = require('./../../assets/images/background/image-11.jpg');


class BlogGrid extends Component {


    render() {
        return (
            <>
                <Header />


                {/* <!--Search Popup--> */}
                <div id="search-popup" className="search-popup">
                    <div className="close-search theme-btn"><span className="flaticon-cancel"></span></div>
                    <div className="popup-inner">
                        <div className="overlay-layer"></div>
                        <div className="search-form">
                            <form method="post" action="http://azim.commonsupport.com/Finandox/index.html">
                                <div className="form-group">
                                    <fieldset>
                                        <input type="search" className="form-control" name="search-input" value="" placeholder="Search Here" required />
                                        <input type="submit" value="Search Now!" className="theme-btn"/>
                                    </fieldset>
                                </div>
                            </form>
                            <br/>
                            <h3>Recent Search Keywords</h3>
                            <ul className="recent-searches">
                                <li><Link to={'/#'}>Finance</Link></li>
                                <li><Link to={'/#'}>Idea</Link></li>
                                <li><Link to={'/#'}>Service</Link></li>
                                <li><Link to={'/#'}>Growth</Link></li>
                                <li><Link to={'/#'}>Plan</Link></li>
                            </ul>
                        </div>
                        
                    </div>
                </div>
                
                {/* <!-- Page Banner Section --> */}
                <section className="page-banner">
                    <div className="page-banner-bg" style={{ backgroundImage: "url(" + aboutbg + ")" }}></div>
                    <div className="bottom-rotten-curve alternate"></div>

                    <div className="auto-container">
                        <h1>Blog Grid</h1>
                        <ul className="bread-crumb clearfix">
                            <li><Link to={'/#'}>Home</Link></li>
                            <li className="active">Blog</li>
                        </ul>
                    </div>

                </section>
                {/* <!--End Banner Section --> */}

                {/* <!-- News Section --> */}
                <section className="news-section">
                    <div className="auto-container">
                        <div className="row">
                            {/* <!-- News Block One --> */}
                            <div className="news-block-one col-lg-4">
                                <div className="inner-box">
                                    <div className="image"><Link to={'/#'}><img src={require('../../assets/images/resource/news-1.jpg')} alt=""/></Link></div>
                                    <div className="lower-content">
                                        <div className="category">Business</div>
                                        <ul className="post-meta">
                                            <li><Link to={'/#'}><i className="far fa-calendar-alt"></i>24th March 2020</Link></li>
                                            <li><Link to={'/#'}><i className="far fa-user"></i>By Admin</Link></li>
                                        </ul>
                                        <h3><Link to={'/blog-details'}>UX is best solution for your <br/>business & make noise.</Link></h3>
                                        <div className="text">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod temp or incididunt ut labore et dolore</div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- News Block One --> */}
                            <div className="news-block-one col-lg-4">
                                <div className="inner-box">
                                    <div className="image"><Link to={'/#'}><img src={require('../../assets/images/resource/news-2.jpg')} alt=""/></Link></div>
                                    <div className="lower-content">
                                        <div className="category">Business</div>
                                        <ul className="post-meta">
                                            <li><Link to={'/#'}><i className="far fa-calendar-alt"></i>26th March 2020</Link></li>
                                            <li><Link to={'/#'}><i className="far fa-user"></i>By Admin</Link></li>
                                        </ul>
                                        <h3><Link to={'/blog-details'}>UX is best solution for your <br/>business & make noise.</Link></h3>
                                        <div className="text">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod temp or incididunt ut labore et dolore</div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- News Block One --> */}
                            <div className="news-block-one col-lg-4">
                                <div className="inner-box">
                                    <div className="image"><Link to={'/#'}><img src={require('../../assets/images/resource/news-3.jpg')} alt=""/></Link></div>
                                    <div className="lower-content">
                                        <div className="category">Business</div>
                                        <ul className="post-meta">
                                            <li><Link to={'/#'}><i className="far fa-calendar-alt"></i>28th March 2020</Link></li>
                                            <li><Link to={'/#'}><i className="far fa-user"></i>By Admin</Link></li>
                                        </ul>
                                        <h3><Link to={'/blog-details'}>UX is best solution for your <br/>business & make noise.</Link></h3>
                                        <div className="text">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod temp or incididunt ut labore et dolore</div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- News Block One --> */}
                            <div className="news-block-one col-lg-4">
                                <div className="inner-box">
                                    <div className="image"><Link to={'/#'}><img src={require('../../assets/images/resource/news-12.jpg')} alt=""/></Link></div>
                                    <div className="lower-content">
                                        <div className="category">Business</div>
                                        <ul className="post-meta">
                                            <li><Link to={'/#'}><i className="far fa-calendar-alt"></i>24th March 2020</Link></li>
                                            <li><Link to={'/#'}><i className="far fa-user"></i>By Admin</Link></li>
                                        </ul>
                                        <h3><Link to={'/blog-details'}>UX is best solution for your <br/>business & make noise.</Link></h3>
                                        <div className="text">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod temp or incididunt ut labore et dolore</div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- News Block One --> */}
                            <div className="news-block-one col-lg-4">
                                <div className="inner-box">
                                    <div className="image"><Link to={'/#'}><img src={require('../../assets/images/resource/news-13.jpg')} alt=""/></Link></div>
                                    <div className="lower-content">
                                        <div className="category">Business</div>
                                        <ul className="post-meta">
                                            <li><Link to={'/#'}><i className="far fa-calendar-alt"></i>26th March 2020</Link></li>
                                            <li><Link to={'/#'}><i className="far fa-user"></i>By Admin</Link></li>
                                        </ul>
                                        <h3><Link to={'/blog-details'}>UX is best solution for your <br/>business & make noise.</Link></h3>
                                        <div className="text">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod temp or incididunt ut labore et dolore</div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- News Block One --> */}
                            <div className="news-block-one col-lg-4">
                                <div className="inner-box">
                                    <div className="image"><Link to={'/#'}><img src={require('../../assets/images/resource/news-14.jpg')} alt=""/></Link></div>
                                    <div className="lower-content">
                                        <div className="category">Business</div>
                                        <ul className="post-meta">
                                            <li><Link to={'/#'}><i className="far fa-calendar-alt"></i>28th March 2020</Link></li>
                                            <li><Link to={'/#'}><i className="far fa-user"></i>By Admin</Link></li>
                                        </ul>
                                        <h3><Link to={'/blog-details'}>UX is best solution for your <br/>business & make noise.</Link></h3>
                                        <div className="text">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod temp or incididunt ut labore et dolore</div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- News Block One --> */}
                            <div className="news-block-one col-lg-4">
                                <div className="inner-box">
                                    <div className="image"><Link to={'/#'}><img src={require('../../assets/images/resource/news-15.jpg')} alt=""/></Link></div>
                                    <div className="lower-content">
                                        <div className="category">Business</div>
                                        <ul className="post-meta">
                                            <li><Link to={'/#'}><i className="far fa-calendar-alt"></i>24th March 2020</Link></li>
                                            <li><Link to={'/#'}><i className="far fa-user"></i>By Admin</Link></li>
                                        </ul>
                                        <h3><Link to={'/blog-details'}>UX is best solution for your <br/>business & make noise.</Link></h3>
                                        <div className="text">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod temp or incididunt ut labore et dolore</div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- News Block One --> */}
                            <div className="news-block-one col-lg-4">
                                <div className="inner-box">
                                    <div className="image"><Link to={'/#'}><img src={require('../../assets/images/resource/news-16.jpg')} alt=""/></Link></div>
                                    <div className="lower-content">
                                        <div className="category">Business</div>
                                        <ul className="post-meta">
                                            <li><Link to={'/#'}><i className="far fa-calendar-alt"></i>26th March 2020</Link></li>
                                            <li><Link to={'/#'}><i className="far fa-user"></i>By Admin</Link></li>
                                        </ul>
                                        <h3><Link to={'/blog-details'}>UX is best solution for your <br/>business & make noise.</Link></h3>
                                        <div className="text">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod temp or incididunt ut labore et dolore</div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- News Block One --> */}
                            <div className="news-block-one col-lg-4">
                                <div className="inner-box">
                                    <div className="image"><Link to={'/#'}><img src={require('../../assets/images/resource/news-17.jpg')} alt=""/></Link></div>
                                    <div className="lower-content">
                                        <div className="category">Business</div>
                                        <ul className="post-meta">
                                            <li><Link to={'/#'}><i className="far fa-calendar-alt"></i>28th March 2020</Link></li>
                                            <li><Link to={'/#'}><i className="far fa-user"></i>By Admin</Link></li>
                                        </ul>
                                        <h3><Link to={'/blog-details'}>UX is best solution for your <br/>business & make noise.</Link></h3>
                                        <div className="text">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod temp or incididunt ut labore et dolore</div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- News Block One --> */}
                            <div className="news-block-one col-lg-4">
                                <div className="inner-box">
                                    <div className="image"><Link to={'/#'}><img src={require('../../assets/images/resource/news-18.jpg')} alt=""/></Link></div>
                                    <div className="lower-content">
                                        <div className="category">Business</div>
                                        <ul className="post-meta">
                                            <li><Link to={'/#'}><i className="far fa-calendar-alt"></i>24th March 2020</Link></li>
                                            <li><Link to={'/#'}><i className="far fa-user"></i>By Admin</Link></li>
                                        </ul>
                                        <h3><Link to={'/blog-details'}>UX is best solution for your <br/>business & make noise.</Link></h3>
                                        <div className="text">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod temp or incididunt ut labore et dolore</div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- News Block One --> */}
                            <div className="news-block-one col-lg-4">
                                <div className="inner-box">
                                    <div className="image"><Link to={'/#'}><img src={require('../../assets/images/resource/news-19.jpg')} alt=""/></Link></div>
                                    <div className="lower-content">
                                        <div className="category">Business</div>
                                        <ul className="post-meta">
                                            <li><Link to={'/#'}><i className="far fa-calendar-alt"></i>26th March 2020</Link></li>
                                            <li><Link to={'/#'}><i className="far fa-user"></i>By Admin</Link></li>
                                        </ul>
                                        <h3><Link to={'/blog-details'}>UX is best solution for your <br/>business & make noise.</Link></h3>
                                        <div className="text">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod temp or incididunt ut labore et dolore</div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- News Block One --> */}
                            <div className="news-block-one col-lg-4">
                                <div className="inner-box">
                                    <div className="image"><Link to={'/#'}><img src={require('../../assets/images/resource/news-20.jpg')} alt=""/></Link></div>
                                    <div className="lower-content">
                                        <div className="category">Business</div>
                                        <ul className="post-meta">
                                            <li><Link to={'/#'}><i className="far fa-calendar-alt"></i>28th March 2020</Link></li>
                                            <li><Link to={'/#'}><i className="far fa-user"></i>By Admin</Link></li>
                                        </ul>
                                        <h3><Link to={'/blog-details'}>UX is best solution for your <br/>business & make noise.</Link></h3>
                                        <div className="text">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod temp or incididunt ut labore et dolore</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <Footer />
            </>
        )
    }
}
export default BlogGrid;