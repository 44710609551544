import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Index from "./pages/index";
import Index3 from "./pages/index-3";
// import Onepage from "./pages/onepage";
// import RtlHome from "./pages/rtl-home";
import Error404 from "./pages/404";
import About from "./pages/about";
import BlogDestails from "./pages/blog-details";
import BlogGrid from "./pages/blog-grid";
import BlogStandard from "./pages/blog-standard";
import Careers from "./pages/careers";
import Checkout from "./pages/checkout";
import Contact from "./pages/contact";
import Faq from "./pages/faq";
import Service from "./pages/service";
import PortfolioDetails from "./pages/service";
import Services2 from "./pages/services-2";
import Shop from "./pages/shop";
import ShoppingCart from "./pages/shopping-cart";
import TeamDetails from "./pages/team-details";
import Team from "./pages/team";
import BackToTop from "./layout/backToTop";
import StartCompanyInBangalore from "./pages/StartCompanyInBangalore";
import ForIndianOwners from "./pages/ForIndianOwners";
import ForIndianOwnersSlug from "./pages/ForIndianOwnersSlug";
import ForForeignOwnersSlug from "./pages/ForForeignOwnersSlug";
import ForForeignOwners from "./pages/ForForeignOwners";
import StartABusiness from "./pages/StartBusiness";

class Router extends Component {
  render() {
    return (
      <BrowserRouter basename={"/"}>
        <div className="page-wrapper">
          <Switch>
            <Route path="/" exact component={Index} />
            {/* <Route path="/index-3" exact component={Index3} /> */}
            <Route path="/for-indian-owners" component={ForIndianOwners} />
            <Route path="/for-foreign-owners" component={ForForeignOwners} />
            <Route
              path="/start-a-company-in-bangalore"
              component={StartCompanyInBangalore}
            />
            <Route path="/start-business" component={StartABusiness} />
            <Route path="/404" component={Error404} />
            <Route path="/about" component={About} />
            {/* <Route path="/careers" component={Careers} /> */}
            <Route path="/contact" component={Contact} />
            <Route path="/services" component={Service} />
            <Route
              path="/for-indian-owners-options/:slug"
              component={ForIndianOwnersSlug}
            />
            <Route
              path="/for-foreign-owners/:slug"
              component={ForForeignOwnersSlug}
            />
            {/* <Route path="/onepage" component={Onepage} /> */}
            {/* <Route path="/rtl-home" component={RtlHome} /> */}

            {/* <Route path="/blog-details" component={BlogDestails} /> */}
            {/* <Route path="/blog-grid" component={BlogGrid} /> */}
            {/* <Route path="/blog-standard" component={BlogStandard} /> */}

            {/* <Route path="/checkout" component={Checkout} /> */}
            {/* <Route path="/faq" component={Faq} /> */}
            {/* <Route path="/portfolio-1" component={Service} />
            <Route path="/portfolio-details" component={PortfolioDetails} /> */}
            {/* <Route path="/services-2" component={Services2} /> */}
            {/* <Route path="/shop" component={Shop} /> */}
            {/* <Route path="/shopping-cart" component={ShoppingCart} /> */}
            {/* <Route path="/team-details" component={TeamDetails} /> */}
            {/* <Route path="/team" component={Team} /> */}
          </Switch>
          <BackToTop />
        </div>
      </BrowserRouter>
    );
  }
}

export default Router;
