import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Nav, Tab } from "react-bootstrap";
import Header3 from "../layout/header3";
import Footer2 from "../layout/footer2";
import ProgressBar from "react-bootstrap/ProgressBar";

const map1 = require("./../../assets/images/shape/shape-6.png");

const ctabg1 = require("./../../assets/images/background/image-10.jpg");

class Index3 extends Component {
  render() {
    return (
      <>
        <Header3 />

        {/* <!-- Hidden Sidebar --> */}
        <section className="hidden-sidebar ">
          <div className="wrapper-box">
            <div className="hidden-sidebar-close">
              <span className="flaticon-cancel"></span>
            </div>
            <div className="logo">
              <Link to={"/#"}>
                <img
                  src={require("../../assets/images/logo-dark.png")}
                  alt=""
                />
              </Link>
            </div>
            <div className="content">
              <div className="about-widget-two sidebar-widget">
                <h3>
                  Smart Business Consultancy <br />
                  in New Milton
                </h3>
                <div className="text">
                  We denounce with righteous indignation and dislike men who we
                  are to beguiled demoralized by the charms of pleasures that
                  moment, so we blinded desires, that they indignations.
                </div>
              </div>
              {/* <!-- News Widget --> */}
              <div className="news-widget sidebar-widget">
                <div className="widget-title">News & Updates</div>
                <div className="post-wrapper">
                  <div className="image">
                    <Link to="blog-details.html">
                      <img
                        src={require("../../assets/images/resource/news-10.jpg")}
                        alt=""
                      />
                    </Link>
                  </div>
                  <div className="category">Business Plans</div>
                  <h4>
                    <Link to={"/blog-details"}>
                      How to Manage Business’s <br />
                      Online Reputation
                    </Link>
                  </h4>
                </div>
                <div className="post-wrapper">
                  <div className="image">
                    <Link to="blog-details.html">
                      <img
                        src={require("../../assets/images/resource/news-11.jpg")}
                        alt=""
                      />
                    </Link>
                  </div>
                  <div className="category">Marketing Stratergy</div>
                  <h4>
                    <Link to={"/blog-details"}>
                      Inside our Daily Routines as a <br />
                      Good Consultant
                    </Link>
                  </h4>
                </div>
              </div>
              {/* <!-- Newsletter Widget --> */}
              <div className="newsletter-widget">
                <div className="widget-title">Newsletter Subscription</div>
                <form action="#">
                  <input type="email" placeholder="Enter Email Address" />
                  <button className="theme-btn btn-style-one">
                    <span className="btn-title">Subscribe Us</span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>

        {/* <!--Search Popup--> */}
        <div id="search-popup" className="search-popup">
          <div className="close-search theme-btn">
            <span className="flaticon-cancel"></span>
          </div>
          <div className="popup-inner">
            <div className="overlay-layer"></div>
            <div className="search-form">
              <form
                method="post"
                action="http://azim.commonsupport.com/Finandox/index.html"
              >
                <div className="form-group">
                  <fieldset>
                    <input
                      type="search"
                      className="form-control"
                      name="search-input"
                      value=""
                      placeholder="Search Here"
                      required
                    />
                    <input
                      type="submit"
                      value="Search Now!"
                      className="theme-btn"
                    />
                  </fieldset>
                </div>
              </form>
              <br />
              <h3>Recent Search Keywords</h3>
              <ul className="recent-searches">
                <li>
                  <Link to={"/#"}>Finance</Link>
                </li>
                <li>
                  <Link to={"/#"}>Idea</Link>
                </li>
                <li>
                  <Link to={"/#"}>Service</Link>
                </li>
                <li>
                  <Link to={"/#"}>Growth</Link>
                </li>
                <li>
                  <Link to={"/#"}>Plan</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* <!-- About Section Two --> */}
        <section className="about-section-three">
          <div className="auto-container">
            <div className="wrapper-box">
              <div className="row align-items-center">
                <div className="col-lg-5">
                  <div className="sec-title text-right">
                    <div className="sub-title">About Us</div>
                    <h2>
                      We Work With <br />
                      You To Address
                    </h2>
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="content-box">
                    <div className="row">
                      <div className="info-column col-md-6">
                        <div className="icon-box">
                          <div className="icon">
                            <img
                              src={require("../../assets/images/icons/icon-19.png")}
                              alt=""
                            />
                          </div>
                          <h5>Phone Number</h5>
                          <h2>+897 6765 754</h2>
                        </div>
                      </div>
                      <div className="info-column col-md-6">
                        <div className="icon-box">
                          <div className="icon">
                            <img
                              src={require("../../assets/images/icons/icon-20.png")}
                              alt=""
                            />
                          </div>
                          <h5>Email Address</h5>
                          <h2>info@webmail.com</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Why Choose Us Section --> */}
        <section className="why-chooseus-section pt-0">
          <div className="auto-container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="image-wrapper">
                  <div className="image-one">
                    <img
                      src={require("../../assets/images/resource/image-11.jpg")}
                      alt=""
                    />
                  </div>
                  <div className="image-two">
                    <img
                      src={require("../../assets/images/resource/image-12.jpg")}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="content-box ml-lg-3">
                  <div className="sec-title">
                    <div className="sub-title">Why Choose Us</div>
                    <h2>
                      Why Should You <br />
                      Choose Us ?
                    </h2>
                  </div>
                  <Tab.Container defaultActiveKey="first">
                    <Nav
                      variant="pills"
                      className="nav nav-tabs tab-btn-style-one"
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="first">
                          <span>Our Mission</span>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">
                          <span>Our Vission</span>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">
                          <span>Our Value</span>
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content className="tabs-content">
                      <Tab.Pane eventKey="first" className="fadeInUp animated">
                        <div className="clearfix">
                          <div className="image">
                            <img
                              src={require("../../assets/images/resource/image-4.jpg")}
                              alt=""
                            />
                          </div>
                          <div className="text">
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipisici
                              ng elit, sed do eiusmod tempor incididunt ut labo
                              re et dolore magna aliqua. Ut enim ad minim veni
                              am, quis nostrud exercitation ullamco.
                            </p>
                            <p>
                              Duis aute irure dolor in reprehenderit in
                              voluptate velit esse cillum dolore eu fugiat nulla
                              pariatur. Excepteur sint occaecat cupidatat non
                              proident, sunt in culpa qui officia deserunt
                              mollit anim id est laborum. Sed ut perspiciatis
                              unde omnis iste natus error
                            </p>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second" className="fadeInUp animated">
                        <div className="clearfix">
                          <div className="image">
                            <img
                              src={require("../../assets/images/resource/image-4.jpg")}
                              alt=""
                            />
                          </div>
                          <div className="text">
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipisici
                              ng elit, sed do eiusmod tempor incididunt ut labo
                              re et dolore magna aliqua. Ut enim ad minim veni
                              am, quis nostrud exercitation ullamco.
                            </p>
                            <p>
                              Duis aute irure dolor in reprehenderit in
                              voluptate velit esse cillum dolore eu fugiat nulla
                              pariatur. Excepteur sint occaecat cupidatat non
                              proident, sunt in culpa qui officia deserunt
                              mollit anim id est laborum. Sed ut perspiciatis
                              unde omnis iste natus error
                            </p>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third" className="fadeInUp animated">
                        <div className="clearfix">
                          <div className="image">
                            <img
                              src={require("../../assets/images/resource/image-4.jpg")}
                              alt=""
                            />
                          </div>
                          <div className="text">
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipisici
                              ng elit, sed do eiusmod tempor incididunt ut labo
                              re et dolore magna aliqua. Ut enim ad minim veni
                              am, quis nostrud exercitation ullamco.
                            </p>
                            <p>
                              Duis aute irure dolor in reprehenderit in
                              voluptate velit esse cillum dolore eu fugiat nulla
                              pariatur. Excepteur sint occaecat cupidatat non
                              proident, sunt in culpa qui officia deserunt
                              mollit anim id est laborum. Sed ut perspiciatis
                              unde omnis iste natus error
                            </p>
                          </div>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Services Section Three --> */}
        <section className="services-section-three">
          <div className="auto-container">
            <div className="sec-title text-center">
              <div className="sub-title">Services</div>
              <h2>What We Do</h2>
            </div>
            <div className="row">
              {/* <!-- Services Block One --> */}
              <div className="service-block-one col-lg-4">
                <div className="inner-box">
                  <div className="icon">
                    <span className="flaticon-assets"></span>
                    <span className="round-shape"></span>
                  </div>
                  <h3>Business/Family Loan</h3>
                  <div className="text">
                    Lorem ipsum dolor sit amet, consectet ur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore.
                  </div>
                </div>
              </div>
              {/* <!-- Services Block One --> */}
              <div className="service-block-one col-lg-4">
                <div className="inner-box">
                  <div className="icon">
                    <span className="flaticon-life-insurance"></span>
                    <span className="round-shape"></span>
                  </div>
                  <h3>Life/Car Insurance</h3>
                  <div className="text">
                    Lorem ipsum dolor sit amet, consectet ur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore.
                  </div>
                </div>
              </div>
              {/* <!-- Services Block One --> */}
              <div className="service-block-one col-lg-4">
                <div className="inner-box">
                  <div className="icon">
                    <span className="flaticon-consulting"></span>
                    <span className="round-shape"></span>
                  </div>
                  <h3>Business Consultancy</h3>
                  <div className="text">
                    Lorem ipsum dolor sit amet, consectet ur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Insurance Calculate --> */}
        <section className="insurance-calculate">
          <div className="big-title">Insurance</div>
          <div className="auto-container">
            <div className="wrapper-box">
              <div
                className="shape"
                style={{ backgroundImage: "url(" + map1 + ")" }}
              ></div>
              <h1>Life Insurance Quote</h1>
              <div className="price">
                <span>$10000</span>
                <span>$20000</span>
                <span>$30000</span>
                <span>$40000</span>
                <span>$50000</span>
              </div>
              <div className="progress-box">
                <div className="bar">
                  <ProgressBar className="count-bar" now={64} />
                </div>
              </div>
              <div className="lower-content">
                <div className="column">
                  <h5>Profit</h5>
                  <h2>$35,000</h2>
                </div>
                <div className="column">
                  <h5>Duration</h5>
                  <h2>05 Mo.</h2>
                </div>
                <div className="column">
                  <h5>Monthly</h5>
                  <h2>$7,080</h2>
                </div>
                <div className="column">
                  <Link to={"/#"} className="theme-btn btn-style-two">
                    <span className="btn-title">Get A Quote</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Case Studies --> */}
        <section className="cases-section">
          {/* <!--case Tabs--> */}
          <div className="case-tabs">
            <div className="container-fluid">
              <div className="sec-title text-center">
                <div className="sub-title">Case Study</div>
                <h2>Much Work Done</h2>
              </div>
              <div className="case-tab-wrapper">
                {/* <!--Tabs Content-->   */}
                <div className="case-tabs-content">
                  {/* <!--case Tab / Active Tab--> */}
                  <div className="case-tab active-tab">
                    {/* <CaseStudy /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="map-section">
          <div className="map-column"></div>
        </section>

        {/* <!-- Contact Section Two --> */}
        <section className="contact-section-two">
          <div className="auto-container">
            <div className="contact-info-area">
              <div className="contact-info">
                <div className="row">
                  <div
                    className="info-column col-lg-4 wow fadeInUp"
                    data-wow-delay="200ms"
                    data-wow-duration="1200ms"
                  >
                    <div className="icon-box">
                      <div className="icon">
                        <span className="flaticon-email-6"></span>
                      </div>
                      <h3>Email Address</h3>
                      <ul>
                        <li>
                          <Link to={"/mailto:info@webmail.com"}>
                            info@webmail.com
                          </Link>
                        </li>
                        <li>
                          <Link to={"/mailto:info@webmail.com"}>
                            jobs@exampleco.com
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="info-column col-lg-4 wow fadeInUp"
                    data-wow-delay="200ms"
                    data-wow-duration="1200ms"
                  >
                    <div className="icon-box">
                      <div className="icon">
                        <span className="flaticon-call-1"></span>
                      </div>
                      <h3>Phone Number</h3>
                      <ul>
                        <li>
                          <Link to={"/tel:+8976765654654"}>
                            +897 676 5654 654
                          </Link>
                        </li>
                        <li>
                          <Link to={"/tel:+908(097)56476576"}>
                            +908(097) 564 765 76
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="info-column col-lg-4 wow fadeInUp"
                    data-wow-delay="200ms"
                    data-wow-duration="1200ms"
                  >
                    <div className="icon-box">
                      <div className="icon">
                        <span className="flaticon-location"></span>
                      </div>
                      <h3>Office Address</h3>
                      <ul>
                        <li>
                          12/A, Romania City Town Hall <br />
                          New Joursey, UK
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="contact-form-area">
              <div className="sec-title text-center">
                <div className="sub-title">Write Here</div>
                <h2>Get In Touch</h2>
              </div>
              {/* <!-- Contact Form--> */}
              <div className="contact-form">
                <form
                  method="post"
                  action="http://azim.commonsupport.com/Finandox/sendemail.php"
                  id="contact-form"
                >
                  <div className="row clearfix">
                    <div className="col-md-6 form-group">
                      <label for="name">Enter your name</label>
                      <input
                        type="text"
                        name="username"
                        id="name"
                        placeholder="Enter name here......"
                        required=""
                      />
                      <i className="fas fa-user"></i>
                    </div>

                    <div className="col-md-6 form-group">
                      <label for="email">Enter your email</label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Enter email here......"
                        required=""
                      />
                      <i className="fas fa-envelope"></i>
                    </div>

                    <div className="col-md-12 form-group">
                      <label for="message">Enter your message</label>
                      <textarea
                        name="message"
                        id="message"
                        placeholder="Enter message here......"
                      ></textarea>
                      <i className="fas fa-edit"></i>
                    </div>

                    <div className="col-md-12 form-group">
                      <button
                        className="theme-btn btn-style-one"
                        type="submit"
                        name="submit-form"
                      >
                        <span className="btn-title">Get In Touch</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>

        <section className="news-section">
          <div className="auto-container">
            <div className="sec-title text-center">
              <div className="sub-title">News</div>
              <h2>News From Resource</h2>
            </div>
            <div className="row">
              {/* <!-- News Block One --> */}
              <div
                className="news-block-one col-lg-4 wow fadeInUp"
                data-wow-delay="200ms"
                data-wow-duration="1200ms"
              >
                <div className="inner-box">
                  <div className="image">
                    <Link to={"/#"}>
                      <img
                        src={require("../../assets/images/resource/news-1.jpg")}
                        alt=""
                      />
                    </Link>
                  </div>
                  <div className="lower-content">
                    <div className="category">Business</div>
                    <ul className="post-meta">
                      <li>
                        <Link to={"/#"}>
                          <i className="far fa-calendar-alt"></i>24th March 2020
                        </Link>
                      </li>
                      <li>
                        <Link to={"/#"}>
                          <i className="far fa-user"></i>By Admin
                        </Link>
                      </li>
                    </ul>
                    <h3>
                      <Link to={"/blog-details"}>
                        UX is best solution for your <br />
                        business & make noise.
                      </Link>
                    </h3>
                    <div className="text">
                      Lorem ipsum dolor sit amet, consectet ur adipisicing elit,
                      sed do eiusmod temp or incididunt ut labore et dolore
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- News Block One --> */}
              <div
                className="news-block-one col-lg-4 wow fadeInUp"
                data-wow-delay="200ms"
                data-wow-duration="1200ms"
              >
                <div className="inner-box">
                  <div className="image">
                    <Link to={"/#"}>
                      <img
                        src={require("../../assets/images/resource/news-2.jpg")}
                        alt=""
                      />
                    </Link>
                  </div>
                  <div className="lower-content">
                    <div className="category">Business</div>
                    <ul className="post-meta">
                      <li>
                        <Link to={"/#"}>
                          <i className="far fa-calendar-alt"></i>26th March 2020
                        </Link>
                      </li>
                      <li>
                        <Link to={"/#"}>
                          <i className="far fa-user"></i>By Admin
                        </Link>
                      </li>
                    </ul>
                    <h3>
                      <Link to={"/blog-details"}>
                        Business consultant is involved in the planning.
                      </Link>
                    </h3>
                    <div className="text">
                      Lorem ipsum dolor sit amet, consectet ur adipisicing elit,
                      sed do eiusmod temp or incididunt ut labore et dolore
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- News Block One --> */}
              <div
                className="news-block-one col-lg-4 wow fadeInUp"
                data-wow-delay="200ms"
                data-wow-duration="1200ms"
              >
                <div className="inner-box">
                  <div className="image">
                    <Link to={"/#"}>
                      <img
                        src={require("../../assets/images/resource/news-3.jpg")}
                        alt=""
                      />
                    </Link>
                  </div>
                  <div className="lower-content">
                    <div className="category">Business</div>
                    <ul className="post-meta">
                      <li>
                        <Link to={"/#"}>
                          <i className="far fa-calendar-alt"></i>28th March 2020
                        </Link>
                      </li>
                      <li>
                        <Link to={"/#"}>
                          <i className="far fa-user"></i>By Admin
                        </Link>
                      </li>
                    </ul>
                    <h3>
                      <Link to={"/blog-details"}>
                        Business consultant is a professional who analyzes.
                      </Link>
                    </h3>
                    <div className="text">
                      Lorem ipsum dolor sit amet, consectet ur adipisicing elit,
                      sed do eiusmod temp or incididunt ut labore et dolore
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- CTA Section --> */}
        <section className="cta-section">
          <div className="auto-container">
            <div
              className="wrapper-box"
              style={{ backgroundImage: "url(" + ctabg1 + ")" }}
            >
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="sec-title light">
                    <div className="sub-title">Call To Action</div>
                    <h2>
                      We Waiting For <br />
                      Your Response.
                    </h2>
                  </div>
                </div>
                <div className="col-lg-6">
                  <form action="#">
                    <input
                      type="email"
                      placeholder="Enter your email address"
                    />
                    <button
                      className="theme-btn btn-style-one"
                      type="submit"
                      name="submit-form"
                    >
                      <span className="btn-title">
                        <i className="fal fa-envelope"></i>Subscribe Now
                      </span>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer2 />
      </>
    );
  }
}
export default Index3;
