import React, { Component } from "react";
import { Link } from "react-router-dom";

class Footer extends Component {
  render() {
    return (
      <>
        <footer className="main-footer">
          <div className="auto-container">
            <div className="widgets-section">
              <div className="row clearfix">
                <div className="column col-lg-4">
                  <div className="footer-widget logo-widget">
                    <div className="widget-content">
                      <div className="footer-logo">
                        <Link to="">
                          <img
                            className=""
                            src={require("../../assets/images/logo.png")}
                            alt="Bridgemark Solutions"
                            width="100"
                            height="50"
                          />
                        </Link>
                      </div>
                      <div className="text">
                        BridgeMark Corporate Services is a dynamic and rapidly
                        expanding professional firm based in Banshankari,
                        Bangalore. with 12 years of diverse experience in the
                        tax domain, our firm specializes in providing a
                        comprehensive array of services, including accounting,
                        tax consulting, and business advisory.
                      </div>
                      <ul className="social-links clearfix">
                        <li>
                          <Link to={"/#"}>
                            <span className="fab fa-linkedin-in"></span>
                          </Link>
                        </li>
                        {/* <li>
                          <Link to={"/#"}>
                            <span className="fab fa-instagram"></span>
                          </Link>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="column col-lg-4">
                  <div className="footer-widget links-widget">
                    <div className="widget-content">
                      <h3>Links</h3>
                      <div className="row">
                        <div className="col-md-6">
                          <ul>
                            <li>
                              <Link to={"/#"}>Home</Link>
                            </li>
                            <li>
                              <Link to={"/about"}>About</Link>
                            </li>
                            <li>
                              <Link to={"/services"}>Services</Link>
                            </li>
                            <li>
                              <Link to={"/careers"}>careers</Link>
                            </li>
                            <li>
                              <Link to={"/contact"}>Contact</Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-6">
                          {/* <ul>
                            <li>
                              <Link to={"/#"}>News</Link>
                            </li>
                            <li>
                              <Link to={"/#"}>Press Release</Link>
                            </li>
                            <li>
                              <Link to={"/#"}>Case Study</Link>
                            </li>
                            <li>
                              <Link to={"/#"}>Terms</Link>
                            </li>
                          </ul> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="column col-lg-4">
                  {/* <div className="footer-widget instagram-widget">
                    <h3>Photo Showcase</h3>
                    <div className="inner-box">
                      <div className="wrapper-box">
                        <div className="image">
                          <img
                            className="lazy-image owl-lazy"
                            src={require("../../assets/images/gallery/gallery-1.jpg")}
                            alt=""
                          />

                          <div className="overlay-link">
                            <Link
                              to={"/assets/images/gallery/gallery-1.jpg"}
                              className="lightbox-image"
                              data-fancybox="gallery"
                            >
                              <span className="fa fa-plus"></span>
                            </Link>
                          </div>
                        </div>
                        <div className="image">
                          <img
                            className="lazy-image owl-lazy"
                            src={require("../../assets/images/gallery/gallery-2.jpg")}
                            alt=""
                          />
                          <div className="overlay-link">
                            <Link
                              to={"/assets/images/gallery/gallery-2.jpg"}
                              className="lightbox-image"
                              data-fancybox="gallery"
                            >
                              <span className="fa fa-plus"></span>
                            </Link>
                          </div>
                        </div>
                        <div className="image">
                          <img
                            className="lazy-image owl-lazy"
                            src={require("../../assets/images/gallery/gallery-3.jpg")}
                            alt=""
                          />
                          <div className="overlay-link">
                            <Link
                              to={"/assets/images/gallery/gallery-3.jpg"}
                              className="lightbox-image"
                              data-fancybox="gallery"
                            >
                              <span className="fa fa-plus"></span>
                            </Link>
                          </div>
                        </div>
                        <div className="image">
                          <img
                            className="lazy-image owl-lazy"
                            src={require("../../assets/images/gallery/gallery-4.jpg")}
                            alt=""
                          />
                          <div className="overlay-link">
                            <Link
                              to={"/assets/images/gallery/gallery-4.jpg"}
                              className="lightbox-image"
                              data-fancybox="gallery"
                            >
                              <span className="fa fa-plus"></span>
                            </Link>
                          </div>
                        </div>
                        <div className="image">
                          <img
                            className="lazy-image owl-lazy"
                            src={require("../../assets/images/gallery/gallery-5.jpg")}
                            alt=""
                          />
                          <div className="overlay-link">
                            <Link
                              to={"/assets/images/gallery/gallery-5.jpg"}
                              className="lightbox-image"
                              data-fancybox="gallery"
                            >
                              <span className="fa fa-plus"></span>
                            </Link>
                          </div>
                        </div>
                        <div className="image">
                          <img
                            className="lazy-image owl-lazy"
                            src={require("../../assets/images/gallery/gallery-6.jpg")}
                            alt=""
                          />
                          <div className="overlay-link">
                            <Link
                              to={"/assets/images/gallery/gallery-6.jpg"}
                              className="lightbox-image"
                              data-fancybox="gallery"
                            >
                              <span className="fa fa-plus"></span>
                            </Link>
                          </div>
                        </div>
                        <div className="image">
                          <img
                            className="lazy-image owl-lazy"
                            src={require("../../assets/images/gallery/gallery-7.jpg")}
                            alt=""
                          />
                          <div className="overlay-link">
                            <Link
                              to={"/assets/images/gallery/gallery-7.jpg"}
                              className="lightbox-image"
                              data-fancybox="gallery"
                            >
                              <span className="fa fa-plus"></span>
                            </Link>
                          </div>
                        </div>
                        <div className="image">
                          <img
                            className="lazy-image owl-lazy"
                            src={require("../../assets/images/gallery/gallery-8.jpg")}
                            alt=""
                          />
                          <div className="overlay-link">
                            <Link
                              to={"/assets/images/gallery/gallery-8.jpg"}
                              className="lightbox-image"
                              data-fancybox="gallery"
                            >
                              <span className="fa fa-plus"></span>
                            </Link>
                          </div>
                        </div>
                        <div className="image">
                          <img
                            className="lazy-image owl-lazy"
                            src={require("../../assets/images/gallery/gallery-9.jpg")}
                            alt=""
                          />
                          <div className="overlay-link">
                            <Link
                              to={"/assets/images/gallery/gallery-9.jpg"}
                              className="lightbox-image"
                              data-fancybox="gallery"
                            >
                              <span className="fa fa-plus"></span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="auto-container">
            <div className="footer-bottom">
              <div className="copyright">
                Copyright By <Link to={"/#"}>Bridgemark Corporation </Link>
                {new Date().getFullYear()}
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

export default Footer;
