import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../layout/header";
import Footer from "../layout/footer";

const aboutbg = require("./../../assets/images/background/image-11.jpg");

class About extends Component {
  ourService = [
    {
      label: "Accounting Services",
      index: 0,
      discription:
        "<div>Accurate and compliant financial record-keeping to support business operations.</div>",
    },
    {
      label: "Tax Consulting",
      index: 1,
      discription:
        "<div>Expert guidance on direct and indirect tax practices to optimize tax positions and ensure compliance.</div>",
    },
    {
      label: "Business Advisory",
      index: 2,
      discription:
        "<div>Tailored solutions for entrepreneurs, addressing legal, regulatory, and financial considerations in setting up and managing businesses in India.</div>",
    },
    {
      label: "Business Registration",
      index: 3,
      discription:
        "<div>Services encompassing Company Incorporation and other business registration processes.</div>",
    },
    {
      label: "Audits",
      index: 4,
      discription:
        "<ul><li><strong>Internal Audit:</strong> Comprehensive internal audit services ensuring adherence to best practices.&nbsp;</li><li><strong>Stock Audit:</strong> Thorough examination of stock and inventory management processes.</li></ul><p><br></p>",
    },
    {
      label: "MCA & ROC Compliance",
      index: 5,
      discription:
        "<p>Assistance with compliance requirements under the Ministry of Corporate Affairs and Registrar of Companies.</p>",
    },
    {
      label: "RBI Returns and Foreign Assets and Liabilities Returns",
      index: 6,
      discription:
        "<p>Expertise in filing returns with the Reserve Bank of India and managing foreign assets and liabilities.</p>",
    },
    {
      label: "International Taxation Compliance",
      index: 7,
      discription:
        "<ul><li><strong>DTAA (Double Tax Avoidance Agreements):</strong> Guidance on international tax treaties to prevent double taxation.</li><li><strong>Transfer Pricing:</strong> Ensuring compliance with regulations governing transfer pricing.</li><li><strong>DGFT (Directorate General of Foreign Trade):</strong> Assistance with matters related to foreign trade.</li><li><strong>IEC Codes:&nbsp;</strong>Facilitating the issuance and management of Importer-Exporter Codes.</li><li><strong>Foreign Capital Transfer Process:</strong> Expertise in the smooth transfer of foreign capital in compliance with regulations.</li></ul><p><br></p>",
    },
  ];

  renderService = () => {
    return this.ourService.map((item, index) => {
      return (
        <div className="col-lg-6" key={index}>
          <div className="icon-box pl-0">
            {/* <div className="icon">
              <img
                src={require("../../assets/images/icons/icon-3.png")}
                alt=""
              />
            </div> */}
            <h2>{item.label}</h2>
            <div className="text">
              <div dangerouslySetInnerHTML={{ __html: item.discription }} />
            </div>
          </div>
        </div>
      );
    });
  };
  render() {
    return (
      <>
        <Header />
        <div id="search-popup" className="search-popup">
          <div className="close-search theme-btn">
            <span className="flaticon-cancel"></span>
          </div>
          <div className="popup-inner">
            <div className="overlay-layer"></div>
            <div className="search-form">
              <form
                method="post"
                action="http://azim.commonsupport.com/Finandox/index.html"
              >
                <div className="form-group">
                  <fieldset>
                    <input
                      type="search"
                      className="form-control"
                      name="search-input"
                      value=""
                      placeholder="Search Here"
                      required
                    />
                    <input
                      type="submit"
                      value="Search Now!"
                      className="theme-btn"
                    />
                  </fieldset>
                </div>
              </form>
              <br />
              <h3>Recent Search Keywords</h3>
              <ul className="recent-searches">
                <li>
                  <Link to={"/#"}>Finance</Link>
                </li>
                <li>
                  <Link to={"/#"}>Idea</Link>
                </li>
                <li>
                  <Link to={"/#"}>Service</Link>
                </li>
                <li>
                  <Link to={"/#"}>Growth</Link>
                </li>
                <li>
                  <Link to={"/#"}>Plan</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <section className="page-banner">
          <div
            className="page-banner-bg"
            style={{ backgroundImage: "url(" + aboutbg + ")" }}
          ></div>
          <div className="bottom-rotten-curve alternate"></div>

          <div className="auto-container">
            <h1>About Us</h1>
            <ul className="bread-crumb clearfix">
              <li>
                <Link to={"/#"}>Home</Link>
              </li>
              <li className="active">About Us</li>
            </ul>
          </div>
        </section>

        {/* About Section Two */}
        <section className="about-section-two">
          <div className="auto-container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="image-wrapper">
                  <div className="image-one">
                    <img
                      src={require("../../assets/images/resource/team-1.jpg")}
                      alt="employees provident fund organization"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="content-box">
                  <div className="sec-title">
                    <div className="sub-title">About us</div>
                    <h2>
                      We Work With <br />
                      You To Address
                    </h2>
                    <div className="text">
                    BridgeMark Corporate Services is a dynamic and rapidly expanding professional services firm based in Banshankari, Bangalore. Established in 2018 by Mr. Freeman Prem D Almeida, a seasoned expert with over 12 years of diverse experience in the tax consulting industry, our firm specializes in offering a comprehensive range of accounting services, tax consulting, and business advisory solutions.
                    <br></br>

                    We cater to businesses of all sizes, providing expert guidance in tax planning, financial reporting, audit services, and strategic business consulting. Our commitment to excellence and client satisfaction sets us apart as a leading accounting firm in Bangalore, dedicated to delivering tailored solutions that drive growth and financial success.
                   
                    </div>
                  </div>
                  <div className="author-info float-right">
                    <h2>Freeman Prem D Almeida</h2>
                    <div className="wrapper-box">
                      {/* <div className="designation">Founder</div>
                      <div className="text">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit, sed do eiusmod..
                      </div> */}
                    </div>
                    {/* <div className="signature">
                      <img
                        src={require("../../assets/images/resource/signature.png")}
                        alt=""
                      />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* History Section */}
        {/* <section className="history-section">
          <div className="auto-container">
            <div className="sec-title text-center light">
              <div className="sub-title">History</div>
              <h2>Our Journey</h2>
            </div>
            <Tab.Container defaultActiveKey="first">
              <Nav className="nav-tabs tab-btn-style-one">
                <Nav.Item>
                  <Nav.Link eventKey="first">2020</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">2015</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="third">2010</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="fourth">2005</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="fifth">2000</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="first" className=" fadeInUp animated">
                  <div className="row align-items-center">
                    <div className="col-lg-6">
                      <div className="image">
                        <img
                          src={require("../../assets/images/resource/image-18.jpg")}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="content pl-lg-4">
                        <div className="sec-title light">
                          <div className="sub-title">Get Rewards</div>
                          <h2>
                            Just Proved Our Selves <br />
                            For Great Works.
                          </h2>
                        </div>
                        <div className="text">
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum.
                        </div>
                        <div className="link-btn">
                          <Link to={"/#"} className="theme-btn btn-style-one">
                            <span className="btn-title">Learn More</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="second" className=" fadeInUp animated">
                  <div className="row align-items-center">
                    <div className="col-lg-6">
                      <div className="image">
                        <img
                          src={require("../../assets/images/resource/image-18.jpg")}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="content pl-lg-4">
                        <div className="sec-title light">
                          <div className="sub-title">Get Rewards</div>
                          <h2>
                            Just Proved Our Selves <br />
                            For Great Works.
                          </h2>
                        </div>
                        <div className="text">
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum.
                        </div>
                        <div className="link-btn">
                          <Link to={"/#"} className="theme-btn btn-style-one">
                            <span className="btn-title">Learn More</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="third" className=" fadeInUp animated">
                  <div className="row align-items-center">
                    <div className="col-lg-6">
                      <div className="image">
                        <img
                          src={require("../../assets/images/resource/image-18.jpg")}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="content pl-lg-4">
                        <div className="sec-title light">
                          <div className="sub-title">Get Rewards</div>
                          <h2>
                            Just Proved Our Selves <br />
                            For Great Works.
                          </h2>
                        </div>
                        <div className="text">
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum.
                        </div>
                        <div className="link-btn">
                          <Link to={"/#"} className="theme-btn btn-style-one">
                            <span className="btn-title">Learn More</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="fourth" className=" fadeInUp animated">
                  <div className="row align-items-center">
                    <div className="col-lg-6">
                      <div className="image">
                        <img
                          src={require("../../assets/images/resource/image-18.jpg")}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="content pl-lg-4">
                        <div className="sec-title light">
                          <div className="sub-title">Get Rewards</div>
                          <h2>
                            Just Proved Our Selves <br />
                            For Great Works.
                          </h2>
                        </div>
                        <div className="text">
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum.
                        </div>
                        <div className="link-btn">
                          <Link to={"/#"} className="theme-btn btn-style-one">
                            <span className="btn-title">Learn More</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="fifth" className=" fadeInUp animated">
                  <div className="row align-items-center">
                    <div className="col-lg-6">
                      <div className="image">
                        <img
                          src={require("../../assets/images/resource/image-18.jpg")}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="content pl-lg-4">
                        <div className="sec-title light">
                          <div className="sub-title">Get Rewards</div>
                          <h2>
                            Just Proved Our Selves <br />
                            For Great Works.
                          </h2>
                        </div>
                        <div className="text">
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum.
                        </div>
                        <div className="link-btn">
                          <Link to={"/#"} className="theme-btn btn-style-one">
                            <span className="btn-title">Learn More</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </section> */}

        {/* Team Section */}
        {/* <section className="team-section">
          <div className="auto-container">
            <div className="sec-title text-center">
              <div className="sub-title">Our Team</div>
              <h2>Leadership Team</h2>
            </div>
            <div className="row"> */}
        {/* Team Block One */}
        {/* <div className="col-lg-4 team-block-one">
                <div className="inner-box">
                  <div className="image">
                    <img
                      src={require("../../assets/images/resource/team-1.jpg")}
                      alt=""
                    />
                  </div>
                  <div className="content">
                    <div className="designation">Founder</div>
                    <h3>Freeman Prem D Almeida</h3>
                    <ul className="social-links">
                      <li>
                        <Link to={"/#"}>
                          <span className="fab fa-facebook-f"></span>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/#"}>
                          <span className="fab fa-linkedin"></span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div> */}
        {/* </div>
          </div>
        </section> */}

        {/* Services Section Four */}
        <section className="services-section-four services-section-about">
          <div className="auto-container">
            <div className="sec-title text-center">
              <div className="sub-title">Services</div>
              <h2>Our Services</h2>
            </div>
            <div className="row">{this.renderService()}</div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}
export default About;
