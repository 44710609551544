import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../layout/header";
import Footer from "../layout/footer";

const aboutbg = require("./../../assets/images/background/image-11.jpg");
const touchbg = require("./../../assets/images/background/image-8.jpg");

class Careers extends Component {
  constructor(props) {
    super(props);
    this.openings = [
      {
        role: "Accountant",
        discription:
          "We are seeking a highly motivated Accountant with 5-6 years of experience",
        location: "Bengalore",
        salary: "As Per Industry Standars",
        experiance: "5-6 Years",
        id: 1,
      },
      {
        role: "HR Payroll Person",
        discription:
          "We are seeking a highly motivated HR Payroll Person with 5-6 years of experience",
        location: "Bengalore",
        salary: "As Per Industry Standars",
        experiance: "5-6 Years",
        id: 2,
      },
    ];
  }

  renderCareere() {
    return this.openings.map((item, index) => {
      return (
        <div className="col-lg-4 col-md-6 career-block" key={index}>
          {/* <Link to={`/career/${item.id}`}> */}
          <div className="inner-box">
            <div className="time">{item.role}</div>
            {/* <h3>{item.role}</h3> */}
            <div className="text">{item.discription}</div>
            <ul className="info">
              <li>
                <strong>Location:</strong>
                {item.location === "" ? " -" : item.location}
              </li>
              <li>
                <strong>Salary:</strong>
                {item.salary === "" ? " -" : item.salary}
              </li>
              <li>
                <strong>Experience:</strong>
                {item.salary === "" ? " -" : item.experiance}
              </li>
            </ul>
          </div>
          {/* </Link> */}
        </div>
      );
    });
  }

  render() {
    return (
      <>
        <Header />
        {/* <!-- Page Banner Section --> */}
        <section className="page-banner">
          <div
            className="page-banner-bg"
            style={{ backgroundImage: "url(" + aboutbg + ")" }}
          ></div>
          <div className="bottom-rotten-curve alternate"></div>

          <div className="auto-container">
            <h1>Careers</h1>
            <ul className="bread-crumb clearfix">
              <li>
                <Link to={"/#"}>Home</Link>
              </li>
              <li className="active">Careers</li>
            </ul>
          </div>
        </section>
        {/* <!--End Banner Section --> */}

        {/* <!-- Careers Section --> */}
        <section className="careers-section">
          <div className="auto-container">
            <div className="row">{this.renderCareere()}</div>
          </div>
        </section>
        <section className="services-section-two services-section-careers">
          <div className="auto-container">
            <div className="wrapper-box">
              <div
                className="left-column"
                style={{ backgroundImage: "url(" + touchbg + ")" }}
              >
                <div className="sec-title light">
                  <div className="sub-title">Get In Touch</div>
                  <h2>
                    Get Us Here Or <br /> Share Details
                  </h2>
                </div>
                {/* <!-- Contact Form--> */}
                <div className="contact-form"></div>
              </div>
              <div className="right-column">
                <div className="services-content">
                  <iframe
                    src="https://docs.google.com/forms/d/e/1FAIpQLSfEbOMrOeiGH0Y5QtAYeSSVDmW7Xgo0ILxyDJFSZufw-ue_vg/viewform?embedded=true"
                    width="100%"
                    height="1000"
                    frameBorder="0"
                    marginHeight="0"
                    marginWidth="0"
                    title="Bridgemark"
                  >
                    Loading…
                  </iframe>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}
export default Careers;
