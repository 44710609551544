import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header'
import Footer from '../layout/footer'

const aboutbg = require('./../../assets/images/background/image-11.jpg');

class TeamDetails extends Component {


    render() {
        return (
            <>
                <Header/>
                {/* <!--Search Popup--> */}
                <div id="search-popup" className="search-popup">
                    <div className="close-search theme-btn"><span className="flaticon-cancel"></span></div>
                    <div className="popup-inner">
                        <div className="overlay-layer"></div>
                        <div className="search-form">
                            <form method="post" action="http://azim.commonsupport.com/Finandox/index.html">
                                <div className="form-group">
                                    <fieldset>
                                        <input type="search" className="form-control" name="search-input" value="" placeholder="Search Here" required />
                                        <input type="submit" value="Search Now!" className="theme-btn"/>
                                    </fieldset>
                                </div>
                            </form>
                            <br/>
                            <h3>Recent Search Keywords</h3>
                            <ul className="recent-searches">
                                <li><Link to={'/#'}>Finance</Link></li>
                                <li><Link to={'/#'}>Idea</Link></li>
                                <li><Link to={'/#'}>Service</Link></li>
                                <li><Link to={'/#'}>Growth</Link></li>
                                <li><Link to={'/#'}>Plan</Link></li>
                            </ul>
                        </div>
                        
                    </div>
                </div>
                
                {/* <!-- Page Banner Section --> */}
                <section className="page-banner">
                    <div className="page-banner-bg" style={{ backgroundImage: "url(" + aboutbg + ")" }}></div>
                    <div className="bottom-rotten-curve alternate"></div>

                    <div className="auto-container">
                        <h1>Team Details</h1>
                        <ul className="bread-crumb clearfix">
                            <li><Link to={'/#'}>Home</Link></li>
                            <li className="active">Team Details</li>
                        </ul>
                    </div>

                </section>
                {/* <!--End Banner Section --> */}


                {/* <!-- Team Details --> */}
                <section className="team-details">
                    <div className="auto-container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="image"><img src={require('../../assets/images/resource/team-7.jpg')} alt=""/></div>
                            </div>
                            <div className="col-lg-6 pl-lg-5">
                                <div className="sec-title">
                                    <div className="sub-title">UX Designer</div>
                                    <h2>Rosalina D. William</h2>
                                </div>
                                <div className="text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</div>
                                <div className="progress-box">
                                    <div className="bar">
                                        <div className="bar-inner count-bar" data-percent="90%"><div className="count-text">90%</div></div>
                                    </div>
                                    <h5>UX Design</h5>
                                </div>
                                <div className="link-btn"><Link to={'/#'} className="theme-btn btn-style-two"><span className="btn-title">Contact With Me</span></Link></div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- Experience Section --> */}
                <section className="experience-section">
                    <div className="auto-container">
                        <div className="sec-title text-center">
                            <div className="sub-title">Experience</div>
                            <h2>Previous Clients</h2>
                        </div>
                        <div className="row">
                            <div className="experience-block col-lg-4">
                                <div className="inner-box">
                                    <h3>Google Co Ltd.</h3>
                                    <h5>UX Design</h5>
                                    <div className="read-more-btn"><i className="fal fa-arrow-right"></i></div>
                                </div>
                            </div>
                            <div className="experience-block col-lg-4">
                                <div className="inner-box">
                                    <h3>Apple Co Ltd.</h3>
                                    <h5>UX Design</h5>
                                </div>
                            </div>
                            <div className="experience-block col-lg-4">
                                <div className="inner-box">
                                    <h3>Logitech Ltd.</h3>
                                    <h5>UX Design</h5>
                                </div>
                            </div>
                            <div className="experience-block col-lg-4">
                                <div className="inner-box">
                                    <h3>A4Tech</h3>
                                    <h5>UX Design</h5>
                                </div>
                            </div>
                            <div className="experience-block col-lg-4">
                                <div className="inner-box">
                                    <h3>Lenovo Inc.</h3>
                                    <h5>UX Design</h5>
                                </div>
                            </div>
                            <div className="experience-block col-lg-4">
                                <div className="inner-box">
                                    <h3>Ovona Co.</h3>
                                    <h5>UX Design</h5>
                                </div>
                            </div>
                            <div className="experience-block col-lg-4">
                                <div className="inner-box">
                                    <h3>Kixer Glass Co.</h3>
                                    <h5>UX Design</h5>
                                </div>
                            </div>
                            <div className="experience-block col-lg-4">
                                <div className="inner-box">
                                    <h3>Setlean Print Co.</h3>
                                    <h5>UX Design</h5>
                                </div>
                            </div>
                            <div className="experience-block col-lg-4">
                                <div className="inner-box">
                                    <h3>Hali Insurance Co.</h3>
                                    <h5>UX Design</h5>
                                </div>
                            </div>
                        </div>
                        <div className="link-btn text-center"><Link to={'/#'} className="theme-btn btn-style-one"><span className="btn-title">Contact With Me</span></Link></div>
                    </div>
                </section>

                {/* <!-- Case Studies --> */}
                <section className="cases-section">
                            
                    {/* <!--case Tabs--> */}
                    <div className="case-tabs">
                        <div className="auto-container">
                            <div className="sec-title text-center">
                                <div className="sub-title">Portfolio</div>
                                <h2>Our Works</h2>
                            </div>
                            {/* <!--Tabs Header--> */}
                            <div className="tabs-header">
                                <ul className="case-tab-btns flex-box">
                                    <li className="case-tab-btn active-btn" data-tab="#case-tab-1"><span>Financial</span></li>
                                    <li className="case-tab-btn" data-tab="#case-tab-2"><span>Banking</span></li>
                                    <li className="case-tab-btn" data-tab="#case-tab-3"><span>Insurance</span></li>
                                    <li className="case-tab-btn" data-tab="#case-tab-4"><span>Family</span></li>
                                    <li className="case-tab-btn" data-tab="#case-tab-5"><span>Business</span></li>
                                </ul>
                            </div>
                            <div className="case-tab-wrapper">
                                {/* <!--Tabs Content--> */}
                                <div className="case-tabs-content">
                                    {/* <!--case Tab / Active Tab--> */}
                                    <div className="case-tab active-tab" id="case-tab-1">
                                        <div className="theme_carousel owl-theme owl-carousel" data-options='{"loop": true, "margin": 0, "autoheight":true, "lazyload":true, "nav": true, "dots": true, "autoplay": true, "autoplayTimeout": 6000, "smartSpeed": 300, "responsive":{ "0" :{ "items": "1" }, "768" :{ "items" : "1" } , "1000":{ "items" : "1" }}}'>
                                            {/* <!-- case Blokc One --> */}
                                            <div className="case-block-one">
                                                <div className="inner-box">
                                                    <div className="image"><img src={require('../../assets/images/gallery/gallery-24.jpg')} alt=""/></div>
                                                    <div className="overlay">
                                                        <div>
                                                            <h5>Business Consultancy</h5>
                                                            <h2>Brixer Donald Company Business</h2>
                                                            <div className="link-btn flex-box">
                                                                <Link to={'/assets/images/gallery/gallery-24.jpg'} data-fancybox="gallery-1" data-caption="" className="theme-btn"><i>+</i></Link>
                                                            </div>
                                                        </div>                                          
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <!-- case Blokc One --> */}
                                            <div className="case-block-one">
                                                <div className="inner-box">
                                                    <div className="image"><img src={require('../../assets/images/gallery/gallery-25.jpg')} alt=""/></div>
                                                    <div className="overlay">
                                                        <div>
                                                            <h5>Business Consultancy</h5>
                                                            <h2>Brixer Donald Company Business</h2>
                                                            <div className="link-btn flex-box">
                                                                <Link to={'/assets/images/gallery/gallery-24.jpg'} data-fancybox="gallery-1" data-caption="" className="theme-btn"><i>+</i></Link>
                                                            </div>
                                                        </div>                                          
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <!-- case Blokc One --> */}
                                            <div className="case-block-one">
                                                <div className="inner-box">
                                                    <div className="image"><img src={require('../../assets/images/gallery/gallery-26.jpg')} alt=""/></div>
                                                    <div className="overlay">
                                                        <div>
                                                            <h5>Business Consultancy</h5>
                                                            <h2>Brixer Donald Company Business</h2>
                                                            <div className="link-btn flex-box">
                                                                <Link to={'/assets/images/gallery/gallery-24.jpg'} data-fancybox="gallery-1" data-caption="" className="theme-btn"><i>+</i></Link>
                                                            </div>
                                                        </div>                                          
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <!-- case Blokc One --> */}
                                            <div className="case-block-one">
                                                <div className="inner-box">
                                                    <div className="image"><img src={require('../../assets/images/gallery/gallery-24.jpg')} alt=""/></div>
                                                    <div className="overlay">
                                                        <div>
                                                            <h5>Business Consultancy</h5>
                                                            <h2>Brixer Donald Company Business</h2>
                                                            <div className="link-btn flex-box">
                                                                <Link to={'/assets/images/gallery/gallery-24.jpg'} data-fancybox="gallery-1" data-caption="" className="theme-btn"><i>+</i></Link>
                                                            </div>
                                                        </div>                                          
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <!-- case Blokc One --> */}
                                            <div className="case-block-one">
                                                <div className="inner-box">
                                                    <div className="image"><img src={require('../../assets/images/gallery/gallery-25.jpg')} alt=""/></div>
                                                    <div className="overlay">
                                                        <div>
                                                            <h5>Business Consultancy</h5>
                                                            <h2>Brixer Donald Company Business</h2>
                                                            <div className="link-btn flex-box">
                                                                <Link to={'/assets/images/gallery/gallery-24.jpg'} data-fancybox="gallery-1" data-caption="" className="theme-btn"><i>+</i></Link>
                                                            </div>
                                                        </div>                                          
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <!-- case Blokc One --> */}
                                            <div className="case-block-one">
                                                <div className="inner-box">
                                                    <div className="image"><img src={require('../../assets/images/gallery/gallery-26.jpg')} alt=""/></div>
                                                    <div className="overlay">
                                                        <div>
                                                            <h5>Business Consultancy</h5>
                                                            <h2>Brixer Donald Company Business</h2>
                                                            <div className="link-btn flex-box">
                                                                <Link to={'/assets/images/gallery/gallery-24.jpg'} data-fancybox="gallery-1" data-caption="" className="theme-btn"><i>+</i></Link>
                                                            </div>
                                                        </div>                                          
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    {/* <!--case Tab--> */}
                                    <div className="case-tab" id="case-tab-2">
                                        <div className="theme_carousel owl-theme owl-carousel" data-options='{"loop": true, "margin": 0, "autoheight":true, "lazyload":true, "nav": true, "dots": true, "autoplay": true, "autoplayTimeout": 6000, "smartSpeed": 300, "responsive":{ "0" :{ "items": "1" }, "768" :{ "items" : "1" } , "1000":{ "items" : "1" }}}'>
                                            {/* <!-- case Blokc One --> */}
                                            <div className="case-block-one">
                                                <div className="inner-box">
                                                    <div className="image"><img src={require('../../assets/images/gallery/gallery-24.jpg')} alt=""/></div>
                                                    <div className="overlay">
                                                        <div>
                                                            <h5>Business Consultancy</h5>
                                                            <h2>Brixer Donald Company Business</h2>
                                                            <div className="link-btn flex-box">
                                                                <Link to={'/assets/images/gallery/gallery-24.jpg'} data-fancybox="gallery-1" data-caption="" className="theme-btn"><i>+</i></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <!-- case Blokc One --> */}
                                            <div className="case-block-one">
                                                <div className="inner-box">
                                                    <div className="image"><img src={require('../../assets/images/gallery/gallery-25.jpg')} alt=""/></div>
                                                    <div className="overlay">
                                                        <div>
                                                            <h5>Business Consultancy</h5>
                                                            <h2>Brixer Donald Company Business</h2>
                                                            <div className="link-btn flex-box">
                                                                <Link to={'/assets/images/gallery/gallery-24.jpg'} data-fancybox="gallery-1" data-caption="" className="theme-btn"><i>+</i></Link>
                                                            </div>
                                                        </div>                                          
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <!-- case Blokc One --> */}
                                            <div className="case-block-one">
                                                <div className="inner-box">
                                                    <div className="image"><img src={require('../../assets/images/gallery/gallery-26.jpg')} alt=""/></div>
                                                    <div className="overlay">
                                                        <div>
                                                            <h5>Business Consultancy</h5>
                                                            <h2>Brixer Donald Company Business</h2>
                                                            <div className="link-btn flex-box">
                                                                <Link to={'/assets/images/gallery/gallery-24.jpg'} data-fancybox="gallery-1" data-caption="" className="theme-btn"><i>+</i></Link>
                                                            </div>
                                                        </div>                                          
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <!-- case Blokc One --> */}
                                            <div className="case-block-one">
                                                <div className="inner-box">
                                                    <div className="image"><img src={require('../../assets/images/gallery/gallery-24.jpg')} alt=""/></div>
                                                    <div className="overlay">
                                                        <div>
                                                            <h5>Business Consultancy</h5>
                                                            <h2>Brixer Donald Company Business</h2>
                                                            <div className="link-btn flex-box">
                                                                <Link to={'/assets/images/gallery/gallery-24.jpg'} data-fancybox="gallery-1" data-caption="" className="theme-btn"><i>+</i></Link>
                                                            </div>
                                                        </div>                                          
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <!-- case Blokc One --> */}
                                            <div className="case-block-one">
                                                <div className="inner-box">
                                                    <div className="image"><img src={require('../../assets/images/gallery/gallery-25.jpg')} alt=""/></div>
                                                    <div className="overlay">
                                                        <div>
                                                            <h5>Business Consultancy</h5>
                                                            <h2>Brixer Donald Company Business</h2>
                                                            <div className="link-btn flex-box">
                                                                <Link to={'/assets/images/gallery/gallery-24.jpg'} data-fancybox="gallery-1" data-caption="" className="theme-btn"><i>+</i></Link>
                                                            </div>
                                                        </div>                                          
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <!-- case Blokc One --> */}
                                            <div className="case-block-one">
                                                <div className="inner-box">
                                                    <div className="image"><img src={require('../../assets/images/gallery/gallery-26.jpg')} alt=""/></div>
                                                    <div className="overlay">
                                                        <div>
                                                            <h5>Business Consultancy</h5>
                                                            <h2>Brixer Donald Company Business</h2>
                                                            <div className="link-btn flex-box">
                                                                <Link to={'/assets/images/gallery/gallery-24.jpg'} data-fancybox="gallery-1" data-caption="" className="theme-btn"><i>+</i></Link>
                                                            </div>
                                                        </div>                                          
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <!--case Tab--> */}
                                    <div className="case-tab" id="case-tab-3">
                                        <div className="theme_carousel owl-theme owl-carousel" data-options='{"loop": true, "margin": 0, "autoheight":true, "lazyload":true, "nav": true, "dots": true, "autoplay": true, "autoplayTimeout": 6000, "smartSpeed": 300, "responsive":{ "0" :{ "items": "1" }, "768" :{ "items" : "1" } , "1000":{ "items" : "1" }}}'>
                                            {/* <!-- case Blokc One --> */}
                                            <div className="case-block-one">
                                                <div className="inner-box">
                                                    <div className="image"><img src={require('../../assets/images/gallery/gallery-24.jpg')} alt=""/></div>
                                                    <div className="overlay">
                                                        <div>
                                                            <h5>Business Consultancy</h5>
                                                            <h2>Brixer Donald Company Business</h2>
                                                            <div className="link-btn flex-box">
                                                                <Link to={'/assets/images/gallery/gallery-24.jpg'} data-fancybox="gallery-1" data-caption="" className="theme-btn"><i>+</i></Link>
                                                            </div>
                                                        </div>                                          
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <!-- case Blokc One --> */}
                                            <div className="case-block-one">
                                                <div className="inner-box">
                                                    <div className="image"><img src={require('../../assets/images/gallery/gallery-25.jpg')} alt=""/></div>
                                                    <div className="overlay">
                                                        <div>
                                                            <h5>Business Consultancy</h5>
                                                            <h2>Brixer Donald Company Business</h2>
                                                            <div className="link-btn flex-box">
                                                                <Link to={'/assets/images/gallery/gallery-24.jpg'} data-fancybox="gallery-1" data-caption="" className="theme-btn"><i>+</i></Link>
                                                            </div>
                                                        </div>                                          
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <!-- case Blokc One --> */}
                                            <div className="case-block-one">
                                                <div className="inner-box">
                                                    <div className="image"><img src={require('../../assets/images/gallery/gallery-26.jpg')} alt=""/></div>
                                                    <div className="overlay">
                                                        <div>
                                                            <h5>Business Consultancy</h5>
                                                            <h2>Brixer Donald Company Business</h2>
                                                            <div className="link-btn flex-box">
                                                                <Link to={'/assets/images/gallery/gallery-24.jpg'} data-fancybox="gallery-1" data-caption="" className="theme-btn"><i>+</i></Link>
                                                            </div>
                                                        </div>                                          
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <!-- case Blokc One --> */}
                                            <div className="case-block-one">
                                                <div className="inner-box">
                                                    <div className="image"><img src={require('../../assets/images/gallery/gallery-24.jpg')} alt=""/></div>
                                                    <div className="overlay">
                                                        <div>
                                                            <h5>Business Consultancy</h5>
                                                            <h2>Brixer Donald Company Business</h2>
                                                            <div className="link-btn flex-box">
                                                                <Link to={'/assets/images/gallery/gallery-24.jpg'} data-fancybox="gallery-1" data-caption="" className="theme-btn"><i>+</i></Link>
                                                            </div>
                                                        </div>                                          
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <!-- case Blokc One --> */}
                                            <div className="case-block-one">
                                                <div className="inner-box">
                                                    <div className="image"><img src={require('../../assets/images/gallery/gallery-25.jpg')} alt=""/></div>
                                                    <div className="overlay">
                                                        <div>
                                                            <h5>Business Consultancy</h5>
                                                            <h2>Brixer Donald Company Business</h2>
                                                            <div className="link-btn flex-box">
                                                                <Link to={'/assets/images/gallery/gallery-24.jpg'} data-fancybox="gallery-1" data-caption="" className="theme-btn"><i>+</i></Link>
                                                            </div>
                                                        </div>                                          
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <!-- case Blokc One --> */}
                                            <div className="case-block-one">
                                                <div className="inner-box">
                                                    <div className="image"><img src={require('../../assets/images/gallery/gallery-26.jpg')} alt=""/></div>
                                                    <div className="overlay">
                                                        <div>
                                                            <h5>Business Consultancy</h5>
                                                            <h2>Brixer Donald Company Business</h2>
                                                            <div className="link-btn flex-box">
                                                                <Link to={'/assets/images/gallery/gallery-24.jpg'} data-fancybox="gallery-1" data-caption="" className="theme-btn"><i>+</i></Link>
                                                            </div>
                                                        </div>                                          
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <!--case Tab--> */}
                                    <div className="case-tab" id="case-tab-4">
                                        <div className="theme_carousel owl-theme owl-carousel" data-options='{"loop": true, "margin": 0, "autoheight":true, "lazyload":true, "nav": true, "dots": true, "autoplay": true, "autoplayTimeout": 6000, "smartSpeed": 300, "responsive":{ "0" :{ "items": "1" }, "768" :{ "items" : "1" } , "1000":{ "items" : "1" }}}'>
                                            {/* <!-- case Blokc One --> */}
                                            <div className="case-block-one">
                                                <div className="inner-box">
                                                    <div className="image"><img src={require('../../assets/images/gallery/gallery-24.jpg')} alt=""/></div>
                                                    <div className="overlay">
                                                        <div>
                                                            <h5>Business Consultancy</h5>
                                                            <h2>Brixer Donald Company Business</h2>
                                                            <div className="link-btn flex-box">
                                                                <Link to={'/assets/images/gallery/gallery-24.jpg'} data-fancybox="gallery-1" data-caption="" className="theme-btn"><i>+</i></Link>
                                                            </div>
                                                        </div>                                          
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <!-- case Blokc One --> */}
                                            <div className="case-block-one">
                                                <div className="inner-box">
                                                    <div className="image"><img src={require('../../assets/images/gallery/gallery-25.jpg')} alt=""/></div>
                                                    <div className="overlay">
                                                        <div>
                                                            <h5>Business Consultancy</h5>
                                                            <h2>Brixer Donald Company Business</h2>
                                                            <div className="link-btn flex-box">
                                                                <Link to={'/assets/images/gallery/gallery-24.jpg'} data-fancybox="gallery-1" data-caption="" className="theme-btn"><i>+</i></Link>
                                                            </div>
                                                        </div>                                          
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <!-- case Blokc One --> */}
                                            <div className="case-block-one">
                                                <div className="inner-box">
                                                    <div className="image"><img src={require('../../assets/images/gallery/gallery-26.jpg')} alt=""/></div>
                                                    <div className="overlay">
                                                        <div>
                                                            <h5>Business Consultancy</h5>
                                                            <h2>Brixer Donald Company Business</h2>
                                                            <div className="link-btn flex-box">
                                                                <Link to={'/assets/images/gallery/gallery-24.jpg'} data-fancybox="gallery-1" data-caption="" className="theme-btn"><i>+</i></Link>
                                                            </div>
                                                        </div>                                          
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <!-- case Blokc One --> */}
                                            <div className="case-block-one">
                                                <div className="inner-box">
                                                    <div className="image"><img src={require('../../assets/images/gallery/gallery-24.jpg')} alt=""/></div>
                                                    <div className="overlay">
                                                        <div>
                                                            <h5>Business Consultancy</h5>
                                                            <h2>Brixer Donald Company Business</h2>
                                                            <div className="link-btn flex-box">
                                                                <Link to={'/assets/images/gallery/gallery-24.jpg'} data-fancybox="gallery-1" data-caption="" className="theme-btn"><i>+</i></Link>
                                                            </div>
                                                        </div>                                          
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <!-- case Blokc One --> */}
                                            <div className="case-block-one">
                                                <div className="inner-box">
                                                    <div className="image"><img src={require('../../assets/images/gallery/gallery-25.jpg')} alt=""/></div>
                                                    <div className="overlay">
                                                        <div>
                                                            <h5>Business Consultancy</h5>
                                                            <h2>Brixer Donald Company Business</h2>
                                                            <div className="link-btn flex-box">
                                                                <Link to={'/assets/images/gallery/gallery-24.jpg'} data-fancybox="gallery-1" data-caption="" className="theme-btn"><i>+</i></Link>
                                                            </div>
                                                        </div>                                          
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <!-- case Blokc One --> */}
                                            <div className="case-block-one">
                                                <div className="inner-box">
                                                    <div className="image"><img src={require('../../assets/images/gallery/gallery-26.jpg')} alt=""/></div>
                                                    <div className="overlay">
                                                        <div>
                                                            <h5>Business Consultancy</h5>
                                                            <h2>Brixer Donald Company Business</h2>
                                                            <div className="link-btn flex-box">
                                                                <Link to={'/assets/images/gallery/gallery-24.jpg'} data-fancybox="gallery-1" data-caption="" className="theme-btn"><i>+</i></Link>
                                                            </div>
                                                        </div>                                          
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <!--case Tab--> */}
                                    <div className="case-tab" id="case-tab-5">
                                        <div className="theme_carousel owl-theme owl-carousel" data-options='{"loop": true, "margin": 0, "autoheight":true, "lazyload":true, "nav": true, "dots": true, "autoplay": true, "autoplayTimeout": 6000, "smartSpeed": 300, "responsive":{ "0" :{ "items": "1" }, "768" :{ "items" : "1" } , "1000":{ "items" : "1" }}}'>
                                            {/* <!-- case Blokc One --> */}
                                            <div className="case-block-one">
                                                <div className="inner-box">
                                                    <div className="image"><img src={require('../../assets/images/gallery/gallery-24.jpg')} alt=""/></div>
                                                    <div className="overlay">
                                                        <div>
                                                            <h5>Business Consultancy</h5>
                                                            <h2>Brixer Donald Company Business</h2>
                                                            <div className="link-btn flex-box">
                                                                <Link to={'/assets/images/gallery/gallery-24.jpg'} data-fancybox="gallery-1" data-caption="" className="theme-btn"><i>+</i></Link>
                                                            </div>
                                                        </div>                                          
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <!-- case Blokc One --> */}
                                            <div className="case-block-one">
                                                <div className="inner-box">
                                                    <div className="image"><img src={require('../../assets/images/gallery/gallery-25.jpg')} alt=""/></div>
                                                    <div className="overlay">
                                                        <div>
                                                            <h5>Business Consultancy</h5>
                                                            <h2>Brixer Donald Company Business</h2>
                                                            <div className="link-btn flex-box">
                                                                <Link to={'/assets/images/gallery/gallery-24.jpg'} data-fancybox="gallery-1" data-caption="" className="theme-btn"><i>+</i></Link>
                                                            </div>
                                                        </div>                                          
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <!-- case Blokc One --> */}
                                            <div className="case-block-one">
                                                <div className="inner-box">
                                                    <div className="image"><img src={require('../../assets/images/gallery/gallery-26.jpg')} alt=""/></div>
                                                    <div className="overlay">
                                                        <div>
                                                            <h5>Business Consultancy</h5>
                                                            <h2>Brixer Donald Company Business</h2>
                                                            <div className="link-btn flex-box">
                                                                <Link to={'/assets/images/gallery/gallery-24.jpg'} data-fancybox="gallery-1" data-caption="" className="theme-btn"><i>+</i></Link>
                                                            </div>
                                                        </div>                                          
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <!-- case Blokc One --> */}
                                            <div className="case-block-one">
                                                <div className="inner-box">
                                                    <div className="image"><img src={require('../../assets/images/gallery/gallery-24.jpg')} alt=""/></div>
                                                    <div className="overlay">
                                                        <div>
                                                            <h5>Business Consultancy</h5>
                                                            <h2>Brixer Donald Company Business</h2>
                                                            <div className="link-btn flex-box">
                                                                <Link to={'/assets/images/gallery/gallery-24.jpg'} data-fancybox="gallery-1" data-caption="" className="theme-btn"><i>+</i></Link>
                                                            </div>
                                                        </div>                                          
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <!-- case Blokc One --> */}
                                            <div className="case-block-one">
                                                <div className="inner-box">
                                                    <div className="image"><img src={require('../../assets/images/gallery/gallery-25.jpg')} alt=""/></div>
                                                    <div className="overlay">
                                                        <div>
                                                            <h5>Business Consultancy</h5>
                                                            <h2>Brixer Donald Company Business</h2>
                                                            <div className="link-btn flex-box">
                                                                <Link to={'/assets/images/gallery/gallery-24.jpg'} data-fancybox="gallery-1" data-caption="" className="theme-btn"><i>+</i></Link>
                                                            </div>
                                                        </div>                                          
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <!-- case Blokc One --> */}
                                            <div className="case-block-one">
                                                <div className="inner-box">
                                                    <div className="image"><img src={require('../../assets/images/gallery/gallery-26.jpg')} alt=""/></div>
                                                    <div className="overlay">
                                                        <div>
                                                            <h5>Business Consultancy</h5>
                                                            <h2>Brixer Donald Company Business</h2>
                                                            <div className="link-btn flex-box">
                                                                <Link to={'/assets/images/gallery/gallery-24.jpg'} data-fancybox="gallery-1" data-caption="" className="theme-btn"><i>+</i></Link>
                                                            </div>
                                                        </div>                                          
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    </div>
                </section>





                <Footer/>
            </>
        )
    }
}
export default TeamDetails;