import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Nav, Tab } from "react-bootstrap";
import Header from "../layout/header";
import Footer from "../layout/footer";
import HomeSlider1 from "./../element/home-slider1";
import PortfolioFilter1 from "./../element/portfolio-filter1";
const teambg1 = require("./../../assets/images/background/image-3.jpg");
const accounting = require("./../../assets/images/icons/accounting.png");
const auditing = require("./../../assets/images/icons/auditing.png");
const register = require("./../../assets/images/icons/register.png");
const businessregistration = require("./../../assets/images/icons/businessregistration.png");
const taxation = require("./../../assets/images/icons/taxation.png");
const payrollservice = require("./../../assets/images/icons/payrollservice.png");
const licenseservice = require("./../../assets/images/icons/licenseservice.png");

class Index extends Component {
  renderDangerouslySetInnerHTML = (data) => {
    return <div dangerouslySetInnerHTML={{ __html: data }} />;
  };

  renderImage = (image) => {
    if (image.key === "accounting") {
      return <img src={accounting} className="rounded" alt={image.key} />;
    }
    if (image.key === "auditing") {
      return <img src={auditing} className="rounded" alt={image.key} />;
    }
    if (image.key === "payrollservice") {
      return <img src={payrollservice} className="rounded" alt={image.key} />;
    }
    if (image.key === "licenseservice") {
      return <img src={licenseservice} className="rounded" alt={image.key} />;
    }
    if (image.key === "businessregistration") {
      return (
        <img src={businessregistration} className="rounded" alt={image.key} />
      );
    }
    if (image.key === "taxation") {
      return <img src={taxation} className="rounded" alt={image.key} />;
    }
    if (image.key === "register") {
      return <img src={register} className="rounded" alt={image.key} />;
    }
  };

  renderService = () => {
    let servicess = [
      {
        label: "Accounting Service",
        route: "",
        key: "accounting",
        index: 0,
        discription: "",
      },
      {
        label: "License & Registration",
        route: "",
        key: "businessregistration",
        index: 3,
        discription: "",
      },
      {
        label: "Taxation Consulting",
        route: "",
        key: "taxation",
        index: 4,
        discription: "",
      },
      {
        label: "Payroll Service",
        route: "",
        key: "payrollservice",
        index: 1,
        discription: "",
      },
      {
        label: "Audit",
        route: "",
        key: "auditing",
        index: 5,
        discription: "",
      },
      {
        label: "International Taxation Compliance",
        route: "",
        key: "licenseservice",
        index: 2,
        discription: "",
      },
    ];
    return servicess.map((i, index) => {
      return (
        <div
          className="col-12 welcome-block-one col-lg-4 wow fadeInUp"
          data-wow-delay="200ms"
          key={index}
          data-wow-duration="1200ms"
        >
          <Link to={"/services"}>
            <div className="inner-box">
              <div className="icon-box">{this.renderImage(i)}</div>
              <h2>{i.label}</h2>
              <div className="text">
                {this.renderDangerouslySetInnerHTML(i.discription)}
              </div>
            </div>
          </Link>
        </div>
      );
    });
  };

  renderYears = () => {
    const startYear = 2011;
    const currentYear = new Date().getFullYear(); // gets the current year
    const yearsPassed = currentYear - startYear;

    return yearsPassed;
  };

  render() {
    return (
      <>
        <Header />

        {/* <!--Search Popup--> */}
        <div id="search-popup" className="search-popup">
          <div className="close-search theme-btn">
            <span className="flaticon-cancel"></span>
          </div>
          <div className="popup-inner">
            <div className="overlay-layer"></div>
            <div className="search-form">
              <form
                method="post"
                action="http://azim.commonsupport.com/Finandox/index.html"
              >
                <div className="form-group">
                  <fieldset>
                    <input
                      type="search"
                      className="form-control"
                      name="search-input"
                      value=""
                      placeholder="Search Here"
                      required
                    />
                    <input
                      type="submit"
                      value="Search Now!"
                      className="theme-btn"
                    />
                  </fieldset>
                </div>
              </form>
              <br />
              <h3>Recent Search Keywords</h3>
              <ul className="recent-searches">
                <li>
                  <Link to={"/#"}>Finance</Link>
                </li>
                <li>
                  <Link to={"/#"}>Idea</Link>
                </li>
                <li>
                  <Link to={"/#"}>Service</Link>
                </li>
                <li>
                  <Link to={"/#"}>Growth</Link>
                </li>
                <li>
                  <Link to={"/#"}>Plan</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <HomeSlider1 />

        {/* <!-- About Section --> */}
        <section className="about-section">
          <div className="auto-container">
            <div className="row my-5">
              <div className="col-lg-6">
                <img
                  src={require("../../assets/images/resource/about-us.jpg")}
                  alt="Accounts and Bookkeeping"
                  className="my-5 rounded"
                />
              </div>
              <div className="col-lg-6">
                <div className="content-box">
                  <div className="sec-title">
                    <h1>
                      About BridgeMark
                      <br />
                    </h1>
                    <div className="text">
                      <p style={{ textIndent: "50px", textAlign: "justify" }}>
                        BridgeMark Corporate Services is a dynamic and rapidly
                        expanding professional services firm based in
                        Banshankari, Bangalore. Established in 2018 by Mr.
                        Freeman Prem D Almeida, a seasoned expert with over{" "}
                        {this.renderYears()} years of diverse experience in the
                        tax consulting industry, pay roll, our firm specializes
                        in offering a comprehensive range of accounting
                        services, tax consulting, and business advisory
                        solutions.
                      </p>
                      <p style={{ textIndent: "50px", textAlign: "justify" }}>
                        We cater to businesses of all sizes, providing expert
                        guidance in tax planning, financial reporting, audit
                        services, and strategic business consulting. Our
                        commitment to excellence and client satisfaction sets us
                        apart as a leading accounting firm in Bangalore,
                        dedicated to delivering tailored solutions that drive
                        growth and financial success.
                      </p>
                      <br></br>
                    </div>
                    <h5 style={{ textIndent: "50px", textAlign: "justify" }}>
                      <strong>
                        We at BridgeMark our USP would be " We provide tailor
                        made management induction reports which the
                        business owner wants "
                      </strong>
                    </h5>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="icon-box">
                        <div className="icon">
                          <img
                            src={require("../../assets/images/icons/icon-1.png")}
                            alt="Professional services firm"
                          />
                        </div>
                        <h5>Phone Number</h5>
                        <a href={"tel:+917204320381"}>+91 7204320381</a>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <hr className="my-3" />
                    </div>
                    <div className="col-md-12">
                      <div className="icon-box">
                        <div className="icon">
                          <img
                            src={require("../../assets/images/icons/icon-2.png")}
                            alt=""
                          />
                        </div>
                        <h5>Email Address</h5>
                        <h2>
                          <a
                            href="mailto:freeman@bridgemarktech.com"
                            className="text-dark"
                          >
                            freeman@bridgemarktech.com
                          </a>
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="container mt-5 ">
          <div className="row">{this.renderService()}</div>
        </section>

        <section className="container mt-5">
          <div className="row">
            <div className="col-12 col-lg-12">
              <div className="sec-title">
                <h2>
                  Do you want to start a company in Bangalore, India?
                  <br />
                </h2>
                <div className="text">
                  One can set up a business in the form of Proprietary concern,
                  Partnership firm, Limited Liability Partnership or Private
                  Limited Company. The form of business to choose depends on the
                  requirement of the entrepreneur. Indian entrepreneurs have a
                  reasonable understanding of the advantages and disadvantages
                  of each of the forms and it is the foreign companies who have
                  to get a clarity on this aspect.
                </div>
                <div className="text">
                  A foreign company wanting to start their activity in India can
                  choose either (a) a subsidiary company (Private Limited
                  Company) or (b) a branch or liaison office.
                </div>
                <div className="text-right">
                  <h6 className="text-primary">
                    <Link to="/start-a-company-in-bangalore">Read More</Link>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Services Section --> */}
        <section className="services-section">
          <div className="auto-container">
            <div className="wrapper-box">
              <div className="left-column col-12 px-2 px-md-5 py-3 py-md-4">
                <div className="services-content">
                  <div className="sec-title light">
                    {/* <div className="sub-title">Our Services</div> */}
                    {/* <h2>
                      What Actually We <br />
                      Do Here.
                    </h2> */}
                  </div>
                  <div
                    className="icon-box wow fadeInUp px-0"
                    data-wow-delay="200ms"
                    data-wow-duration="1200ms"
                  >
                    <div className="row">
                      <div className="col-12 col-md-2">
                        <div className="icon">
                          <img
                            className=""
                            src={require("../../assets/images/icons/icon-3.png")}
                            alt="Income Tax Return"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-10">
                        <h2>Commitment to Excellence</h2>
                        <div className="text">
                          We uphold the highest standards of professionalism and
                          integrity, dedicated to delivering excellence through
                          meticulous service. Our commitment ensures that
                          clients receive accurate, timely, and effective
                          solutions.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="icon-box wow fadeInUp px-0"
                    data-wow-delay="200ms"
                    data-wow-duration="1200ms"
                  >
                    <div className="row">
                      <div className="col-12 col-md-2">
                        <div className="icon">
                          <img
                            src={require("../../assets/images/icons/icon-4.png")}
                            alt="Tax Planning Experts Bangalore"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-10">
                        <h2>Commitment to Excellence</h2>
                        <div className="text">
                          We uphold the highest standards of professionalism and
                          integrity, dedicated to delivering excellence through
                          meticulous service. Our commitment ensures that
                          clients receive accurate, timely, and effective
                          solutions.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="icon-box wow fadeInUp px-0"
                    data-wow-delay="200ms"
                    data-wow-duration="1200ms"
                  >
                    <div className="row">
                      <div className="col-12 col-md-2">
                        <div className="icon">
                          <img
                            src={require("../../assets/images/icons/icon-5.png")}
                            alt="Corporate Accounting Solutions"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-10">
                        <h2>Our Team and Presence</h2>
                        <div className="text">
                          With a skilled team of 14 professionals located in
                          Banashankari, Bangalore, our collective expertise
                          spans various domains, enabling us to provide holistic
                          solutions. Currently serving 100+ clients, we have
                          established ourselves as a trusted partner in the
                          business consultancy arena.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="mt-5">
          <div className="auto-container">
            <div className="wrapper-box">
              <div className="row justify-content-center">
                <div className="col-lg-2 col-md-4 col-sm-6 col-xs-6 col-6">
                  <div className="feature-block-one">
                    <div className="inner-box border">
                      <div className="icon">
                        <span className="flaticon-money"></span>
                      </div>
                      <h5>Taxation</h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6 col-xs-6 col-6">
                  <div className="feature-block-one">
                    <div className="inner-box border">
                      <div className="icon">
                        <span className="flaticon-assets"></span>
                      </div>
                      <h5>Accounting</h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6 col-xs-6 col-6">
                  <div className="feature-block-one">
                    <div className="inner-box border">
                      <div className="icon">
                        <span className="flaticon-world"></span>
                      </div>
                      <h5>International Taxation</h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6 col-xs-6 col-6">
                  <div className="feature-block-one">
                    <div className="inner-box border">
                      <div className="icon">
                        <span className="flaticon-money-1"></span>
                      </div>
                      <h5>Finance Support</h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6 col-xs-6 col-6">
                  <div className="feature-block-one">
                    <div className="inner-box border">
                      <div className="icon">
                        <span className="flaticon-process"></span>
                      </div>
                      <h5>Audit</h5>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-4 col-sm-6 col-xs-6 col-6">
                  <div className="feature-block-one">
                    <div className="inner-box border">
                      <div className="icon">
                        <span className="flaticon-notebook"></span>
                      </div>
                      <h5>Business Registeration</h5>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-4 col-sm-6 col-xs-6 col-6">
                  <div className="feature-block-one">
                    <div className="inner-box border">
                      <div className="icon">
                        <span className="flaticon-analysis"></span>
                      </div>
                      <h5>Business Advisory</h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6 col-xs-6 col-6">
                  <div className="feature-block-one">
                    <div className="inner-box border">
                      <div className="icon">
                        <span className="flaticon-analysis-2"></span>
                      </div>
                      <h5>Internal Audit</h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6 col-xs-6 col-6">
                  <div className="feature-block-one">
                    <div className="inner-box border">
                      <div className="icon">
                        <span className="flaticon-team"></span>
                      </div>
                      <h5>Company Law Compliance</h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6 col-xs-6 col-6">
                  <div className="feature-block-one">
                    <div className="inner-box border">
                      <div className="icon">
                        <span className="flaticon-business"></span>
                      </div>
                      <h5>MCA & ROC Compliance</h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6 col-xs-6 col-6">
                  <div className="feature-block-one">
                    <div className="inner-box border">
                      <div className="icon">
                        <span className="flaticon-analysis-3"></span>
                      </div>
                      <h5>
                        RBI Returns and Foreign Assets and Liabilities Returns
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Gallery Section --> */}
        <PortfolioFilter1 />

        <section className="why-chooseus-section">
          <div className="auto-container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="content-box">
                  <div className="sec-title">
                    <div className="sub-title">Why Choose Us</div>
                    <h2>
                      Why Should You <br />
                      Choose Us ?
                    </h2>
                  </div>
                  <Tab.Container defaultActiveKey="first">
                    <Nav
                      variant="pills"
                      className="nav nav-tabs tab-btn-style-one"
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="first">
                          <span>Founder's Vision</span>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">
                          <span>Our Team and Presence</span>
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content className="tabs-content">
                      <Tab.Pane eventKey="first" className="fadeInUp animated">
                        <div className="clearfix">
                          <div className="text">
                            <p>
                              At BridgeMark, we envision ourselves as catalysts
                              for simplifying the challenges associated with
                              business setup in India. Mr. Freeman Prem D
                              Almeida's vision is to empower businesses with
                              strategic insights and comprehensive solutions.
                            </p>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second" className="fadeInUp animated">
                        <div className="clearfix">
                          <div className="text">
                            <p>
                              With a skilled team of 14 professionals located in
                              Banashankari, Bangalore, our collective expertise
                              spans various domains, enabling us to provide
                              holistic solutions. Currently serving 100+
                              clients, we have established ourselves as a
                              trusted partner in the business consultancy arena.
                            </p>
                          </div>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="image-wrapper">
                  <div className="image-one">
                    <img
                      src={require("../../assets/images/resource/whyshouldyouchooseus.webp")}
                      alt="skilled team"
                    />
                  </div>
                  {/* <div className="image-two">
                    <img
                      src={require("../../assets/images/resource/image-5.jpg")}
                      alt=""
                    />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Team Section --> */}
        <section
          className="team-section"
          style={{ backgroundImage: "url(" + teambg1 + ")" }}
        >
          <div className="auto-container">
            <div className="sec-title text-center">
              <div className="sub-title">Our Team</div>
              <h2>Leadership Team</h2>
            </div>
            <div className="row">
              {/* <!-- Team Block One --> */}
              <div
                className="col-lg-4 team-block-one wow fadeInUp"
                data-wow-delay="200ms"
                data-wow-duration="1200ms"
              >
                <div className="inner-box">
                  <div className="image">
                    <img
                      src={require("../../assets/images/resource/team-1.jpg")}
                      alt="Founder BridgeMark Corporate Services"
                    />
                  </div>
                  <div className="content">
                    <div className="designation">Founder</div>
                    <h3>Freeman Prem D Almeida</h3>
                    <div className="text">
                      <a href="tel:+917204320381">+91 7204320381</a>
                    </div>
                    <div className="text">freeman@bridgemarktech.com</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Contact Section --> */}
        <section className="contact-section">
          <div className="auto-container">
            <div className="row">
              <div className="col-lg-6">
                <div className="sec-title">
                  <div className="sub-title">Write Here</div>
                  <h2>Get In Touch</h2>
                </div>
                {/* <!-- Contact Form--> */}
                <div className="contact-form">
                  <iframe
                    src="https://docs.google.com/forms/d/e/1FAIpQLSfEbOMrOeiGH0Y5QtAYeSSVDmW7Xgo0ILxyDJFSZufw-ue_vg/viewform?embedded=true"
                    width="100%"
                    height="1000"
                    frameBorder="0"
                    marginHeight="0"
                    marginWidth="0"
                    title="BrideMark"
                  >
                    Loading…
                  </iframe>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="sec-title">
                  <div className="sub-title">Get Us Here</div>
                  <h2>Contact Us</h2>
                </div>
                <div className="contact-info">
                  <div className="border-box">
                    <div className="border_top"></div>
                    <div className="border_middile"></div>
                  </div>
                  <div className="row">
                    <div className="info-column col-md-6">
                      <div className="icon-box">
                        <div className="icon">
                          <span className="flaticon-email-6"></span>
                        </div>
                        <h3>Email Address</h3>
                        <ul>
                          <li>
                            <a href={"mailto:freeman@bridgemarktech.com"}>
                              freeman@bridgemarktech.com
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="info-column col-md-6">
                      <div className="icon-box">
                        <div className="icon">
                          <span className="flaticon-call-1"></span>
                        </div>
                        <h3>Phone Number</h3>
                        <ul>
                          <li>
                            <a href="tel:+917204320381">+91 7204320381</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="info-column col-md-6">
                      <div className="icon-box">
                        <div className="icon">
                          <span className="flaticon-location"></span>
                        </div>
                        <h3>Office Address</h3>
                        <ul>
                          <li>
                            <a
                              href="https://www.google.com/maps/place/BridgeMark+Corporate+Services/@12.9317257,77.5555063,19.55z/data=!4m12!1m5!3m4!2zMTLCsDU1JzU0LjYiTiA3N8KwMzMnMjAuMiJF!8m2!3d12.9318333!4d77.5556111!3m5!1s0x3bae3f33cd5b0bf7:0x3bf18279a76de296!8m2!3d12.9318062!4d77.5556478!16s%2Fg%2F11vzvnd6hv?entry=ttu&g_ep=EgoyMDI0MTAyOS4wIKXMDSoASAFQAw%3D%3D"
                              target="_blank"
                            >
                              # 172, 1st Floor, <br />
                              Kathariguppe Vidya Pita, ITI Layout, <br />
                              Banashankari 3rd Stage, <br />
                              Bangalore (Near Indian Oil Petrol Bunk)
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="info-column col-md-6">
                      <div className="icon-box">
                        <div className="icon">
                          <span className="flaticon-linkedin"></span>
                        </div>
                        <h3>Web Connect</h3>
                        <ul>
                          <li>
                            <Link to={"/"}>bridgemarkcorp</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}
export default Index;
