import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header'
import Footer from '../layout/footer'

const aboutbg = require('./../../assets/images/background/image-11.jpg');
const addbg = require('./../../assets/images/background/image-15.jpg');

class Shop extends Component {


    render() {
        return (
            <>
                <Header />

                {/* <!--Search Popup--> */}
                <div id="search-popup" className="search-popup">
                    <div className="close-search theme-btn"><span className="flaticon-cancel"></span></div>
                    <div className="popup-inner">
                        <div className="overlay-layer"></div>
                        <div className="search-form">
                            <form method="post" action="http://azim.commonsupport.com/Finandox/index.html">
                                <div className="form-group">
                                    <fieldset>
                                        <input type="search" className="form-control" name="search-input" value="" placeholder="Search Here" required />
                                        <input type="submit" value="Search Now!" className="theme-btn" />
                                    </fieldset>
                                </div>
                            </form>
                            <br />
                            <h3>Recent Search Keywords</h3>
                            <ul className="recent-searches">
                                <li><Link to={'/#'}>Finance</Link></li>
                                <li><Link to={'/#'}>Idea</Link></li>
                                <li><Link to={'/#'}>Service</Link></li>
                                <li><Link to={'/#'}>Growth</Link></li>
                                <li><Link to={'/#'}>Plan</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* <!-- Page Banner Section --> */}
                <section className="page-banner">
                    <div className="page-banner-bg" style={{ backgroundImage: "url(" + aboutbg + ")" }}></div>
                    <div className="bottom-rotten-curve alternate"></div>

                    <div className="auto-container">
                        <h1>Product Showcase</h1>
                        <ul className="bread-crumb clearfix">
                            <li><Link to={'/#'}>Home</Link></li>
                            <li className="active">Shop</li>
                        </ul>
                    </div>

                </section>
                {/* <!--End Banner Section --> */}

                {/* <!--Product section--> */}
                <section id="shop-area" className="main-shop-area">
                    <div className="auto-container">
                        <div className="row">
                            <div className="col-lg-9 col-md-12">
                                <div className="shop-content">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="showing-result-shorting">
                                                <div className="showing">
                                                    <p>Showing 1-9 of 35 results</p>
                                                </div>
                                                <div className="shorting">
                                                    <select className="selectmenu">
                                                        <option selected="selected">Default Sorting</option>
                                                        <option>Default Sorting one</option>
                                                        <option>Default Sorting Two</option>
                                                        <option>Default Sorting Three</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {/* <!--Start single product item--> */}
                                        <div className="col-lg-4 col-md-6">
                                            <div className="single-product-item text-center wow fadeInUp" data-wow-delay="300ms">
                                                <div className="img-holder">
                                                    <img src={require('../../assets/images/shop/1.jpg')} alt="Awesome Product " />
                                                    <div className="price">$250</div>
                                                    <div className="overlay-btn"><Link to={'/#'} className="theme-btn btn-style-one"><span className="btn-title">Add To Cart</span></Link></div>
                                                </div>
                                                <div className="title-holder">
                                                    <div className="static-content">
                                                        <div className="category">Business</div>
                                                        <h3 className="title"><Link to={'/shop'}>Office Chair</Link></h3>
                                                        <div className="rating">
                                                            <span className="fa fa-star"></span>
                                                            <span className="fa fa-star"></span>
                                                            <span className="fa fa-star"></span>
                                                            <span className="fa fa-star"></span>
                                                            <span className="fa fa-star"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!--End single product item--> */}
                                        {/* <!--Start single product item--> */}
                                        <div className="col-lg-4 col-md-6">
                                            <div className="single-product-item wow fadeInUp" data-wow-delay="500ms">
                                                <div className="img-holder">
                                                    <img src={require('../../assets/images/shop/2.jpg')} alt="Awesome Product " />
                                                    <div className="price">$250</div>
                                                    <div className="overlay-btn"><Link to={'/#'} className="theme-btn btn-style-one"><span className="btn-title">Add To Cart</span></Link></div>
                                                </div>
                                                <div className="title-holder">
                                                    <div className="static-content">
                                                        <div className="category">Business</div>
                                                        <h3 className="title"><Link to={'/shop'}>One of the best ux book</Link></h3>
                                                        <div className="rating">
                                                            <span className="fa fa-star"></span>
                                                            <span className="fa fa-star"></span>
                                                            <span className="fa fa-star"></span>
                                                            <span className="fa fa-star"></span>
                                                            <span className="fa fa-star"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!--End single product item--> */}
                                        {/* <!--Start single product item--> */}
                                        <div className="col-lg-4 col-md-6">
                                            <div className="single-product-item wow fadeInUp" data-wow-delay="700ms">
                                                <div className="img-holder">
                                                    <img src={require('../../assets/images/shop/3.jpg')} alt="Awesome Product " />
                                                    <div className="price">$250</div>
                                                    <div className="overlay-btn"><Link to={'/#'} className="theme-btn btn-style-one"><span className="btn-title">Add To Cart</span></Link></div>
                                                </div>
                                                <div className="title-holder">
                                                    <div className="static-content">
                                                        <div className="category">Business</div>
                                                        <h3 className="title"><Link to={'/shop'}>Office Chair</Link></h3>
                                                        <div className="rating">
                                                            <span className="fa fa-star"></span>
                                                            <span className="fa fa-star"></span>
                                                            <span className="fa fa-star"></span>
                                                            <span className="fa fa-star"></span>
                                                            <span className="fa fa-star"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!--End single product item--> */}
                                        {/* <!--Start single product item--> */}
                                        <div className="col-lg-4 col-md-6">
                                            <div className="single-product-item wow fadeInUp" data-wow-delay="900ms">
                                                <div className="img-holder">
                                                    <img src={require('../../assets/images/shop/4.jpg')} alt="Awesome Product " />
                                                    <div className="price">$250</div>
                                                    <div className="overlay-btn"><Link to={'/#'} className="theme-btn btn-style-one"><span className="btn-title">Add To Cart</span></Link></div>
                                                </div>
                                                <div className="title-holder">
                                                    <div className="static-content">
                                                        <div className="category">Business</div>
                                                        <h3 className="title"><Link to={'/shop'}>Office Chair</Link></h3>
                                                        <div className="rating">
                                                            <span className="fa fa-star"></span>
                                                            <span className="fa fa-star"></span>
                                                            <span className="fa fa-star"></span>
                                                            <span className="fa fa-star"></span>
                                                            <span className="fa fa-star"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!--End single product item--> */}
                                        {/* <!--Start single product item--> */}
                                        <div className="col-lg-4 col-md-6">
                                            <div className="single-product-item wow fadeInUp" data-wow-delay="1100ms">
                                                <div className="img-holder">
                                                    <img src={require('../../assets/images/shop/5.jpg')} alt="Awesome Product " />
                                                    <div className="price">$250</div>
                                                    <div className="overlay-btn"><Link to={'/#'} className="theme-btn btn-style-one"><span className="btn-title">Add To Cart</span></Link></div>
                                                </div>
                                                <div className="title-holder">
                                                    <div className="static-content">
                                                        <div className="category">Business</div>
                                                        <h3 className="title"><Link to={'/shop'}>Study Lamp</Link></h3>
                                                        <div className="rating">
                                                            <span className="fa fa-star"></span>
                                                            <span className="fa fa-star"></span>
                                                            <span className="fa fa-star"></span>
                                                            <span className="fa fa-star"></span>
                                                            <span className="fa fa-star"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!--End single product item--> */}
                                        {/* <!--Start single product item--> */}
                                        <div className="col-lg-4 col-md-6">
                                            <div className="single-product-item wow fadeInUp" data-wow-delay="1300ms">
                                                <div className="img-holder">
                                                    <img src={require('../../assets/images/shop/6.jpg')} alt="Awesome Product " />
                                                    <div className="price">$250</div>
                                                    <div className="overlay-btn"><Link to={'/#'} className="theme-btn btn-style-one"><span className="btn-title">Add To Cart</span></Link></div>
                                                </div>
                                                <div className="title-holder">
                                                    <div className="static-content">
                                                        <div className="category">Business</div>
                                                        <h3 className="title"><Link to={'/shop'}>Office Chair</Link></h3>
                                                        <div className="rating">
                                                            <span className="fa fa-star"></span>
                                                            <span className="fa fa-star"></span>
                                                            <span className="fa fa-star"></span>
                                                            <span className="fa fa-star"></span>
                                                            <span className="fa fa-star"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!--End single product item--> */}

                                        {/* <!--Start single product item--> */}
                                        <div className="col-lg-4 col-md-6">
                                            <div className="single-product-item text-center wow fadeInUp" data-wow-delay="1500ms">
                                                <div className="img-holder">
                                                    <img src={require('../../assets/images/shop/7.jpg')} alt="Awesome Product " />
                                                    <div className="price">$250</div>
                                                    <div className="overlay-btn"><Link to={'/#'} className="theme-btn btn-style-one"><span className="btn-title">Add To Cart</span></Link></div>
                                                </div>
                                                <div className="title-holder">
                                                    <div className="static-content">
                                                        <div className="category">Business</div>
                                                        <h3 className="title"><Link to={'/shop'}>Office Chair</Link></h3>
                                                        <div className="rating">
                                                            <span className="fa fa-star"></span>
                                                            <span className="fa fa-star"></span>
                                                            <span className="fa fa-star"></span>
                                                            <span className="fa fa-star"></span>
                                                            <span className="fa fa-star"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!--End single product item--> */}
                                        {/* <!--Start single product item--> */}
                                        <div className="col-lg-4 col-md-6">
                                            <div className="single-product-item text-center wow fadeInUp" data-wow-delay="1700ms">
                                                <div className="img-holder">
                                                    <img src={require('../../assets/images/shop/8.jpg')} alt="Awesome Product " />
                                                    <div className="price">$250</div>
                                                    <div className="overlay-btn"><Link to={'/#'} className="theme-btn btn-style-one"><span className="btn-title">Add To Cart</span></Link></div>
                                                </div>
                                                <div className="title-holder">
                                                    <div className="static-content">
                                                        <div className="category">Business</div>
                                                        <h3 className="title"><Link to={'/shop'}>Office Chair</Link></h3>
                                                        <div className="rating">
                                                            <span className="fa fa-star"></span>
                                                            <span className="fa fa-star"></span>
                                                            <span className="fa fa-star"></span>
                                                            <span className="fa fa-star"></span>
                                                            <span className="fa fa-star"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!--End single product item--> */}
                                        {/* <!--Start single product item--> */}
                                        <div className="col-lg-4 col-md-6">
                                            <div className="single-product-item text-center wow fadeInUp" data-wow-delay="1900ms">
                                                <div className="img-holder">
                                                    <img src={require('../../assets/images/shop/9.jpg')} alt="Awesome Product " />
                                                    <div className="price">$250</div>
                                                    <div className="overlay-btn"><Link to={'/#'} className="theme-btn btn-style-one"><span className="btn-title">Add To Cart</span></Link></div>
                                                </div>
                                                <div className="title-holder">
                                                    <div className="static-content">
                                                        <div className="category">Business</div>
                                                        <h3 className="title"><Link to={'/shop'}>Office Chair</Link></h3>
                                                        <div className="rating">
                                                            <span className="fa fa-star"></span>
                                                            <span className="fa fa-star"></span>
                                                            <span className="fa fa-star"></span>
                                                            <span className="fa fa-star"></span>
                                                            <span className="fa fa-star"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!--End single product item--> */}
                                        {/* <!--Start single product item--> */}
                                        <div className="col-lg-4 col-md-6">
                                            <div className="single-product-item text-center wow fadeInUp" data-wow-delay="1900ms">
                                                <div className="img-holder">
                                                    <img src={require('../../assets/images/shop/10.jpg')} alt="Awesome Product " />
                                                    <div className="price">$250</div>
                                                    <div className="overlay-btn"><Link to={'/#'} className="theme-btn btn-style-one"><span className="btn-title">Add To Cart</span></Link></div>
                                                </div>
                                                <div className="title-holder">
                                                    <div className="static-content">
                                                        <div className="category">Business</div>
                                                        <h3 className="title"><Link to={'/shop'}>Office Chair</Link></h3>
                                                        <div className="rating">
                                                            <span className="fa fa-star"></span>
                                                            <span className="fa fa-star"></span>
                                                            <span className="fa fa-star"></span>
                                                            <span className="fa fa-star"></span>
                                                            <span className="fa fa-star"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!--End single product item--> */}
                                        {/* <!--Start single product item--> */}
                                        <div className="col-lg-4 col-md-6">
                                            <div className="single-product-item text-center wow fadeInUp" data-wow-delay="1900ms">
                                                <div className="img-holder">
                                                    <img src={require('../../assets/images/shop/11.jpg')} alt="Awesome Product " />
                                                    <div className="price">$250</div>
                                                    <div className="overlay-btn"><Link to={'/#'} className="theme-btn btn-style-one"><span className="btn-title">Add To Cart</span></Link></div>
                                                </div>
                                                <div className="title-holder">
                                                    <div className="static-content">
                                                        <div className="category">Business</div>
                                                        <h3 className="title"><Link to={'/shop'}>Office Chair</Link></h3>
                                                        <div className="rating">
                                                            <span className="fa fa-star"></span>
                                                            <span className="fa fa-star"></span>
                                                            <span className="fa fa-star"></span>
                                                            <span className="fa fa-star"></span>
                                                            <span className="fa fa-star"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!--End single product item--> */}
                                        {/* <!--Start single product item--> */}
                                        <div className="col-lg-4 col-md-6">
                                            <div className="single-product-item text-center wow fadeInUp" data-wow-delay="1900ms">
                                                <div className="img-holder">
                                                    <img src={require('../../assets/images/shop/12.jpg')} alt="Awesome Product " />
                                                    <div className="price">$250</div>
                                                    <div className="overlay-btn"><Link to={'/#'} className="theme-btn btn-style-one"><span className="btn-title">Add To Cart</span></Link></div>
                                                </div>
                                                <div className="title-holder">
                                                    <div className="static-content">
                                                        <div className="category">Business</div>
                                                        <h3 className="title"><Link to={'/shop'}>Office Chair</Link></h3>
                                                        <div className="rating">
                                                            <span className="fa fa-star"></span>
                                                            <span className="fa fa-star"></span>
                                                            <span className="fa fa-star"></span>
                                                            <span className="fa fa-star"></span>
                                                            <span className="fa fa-star"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!--End single product item--> */}
                                    </div>
                                    {/* <!--Start post pagination--> */}
                                </div>
                            </div>

                            {/* <!--Start sidebar Wrapper--> */}
                            <div className="col-lg-3 col-md-8">
                                <div className="sidebar-wrapper">
                                    {/* <!--Start single sidebar--> */}
                                    <div className="single-sidebar-box-two">
                                        <form className="search-form" action="#">
                                            <input placeholder="Search..." type="text" />
                                            <button type="submit"><i className="fa fa-search" aria-hidden="true"></i></button>
                                        </form>
                                    </div>
                                    {/* <!--End single sidebar--> */}
                                    {/* <!--Start single sidebar--> */}
                                    <div className="single-sidebar-box">
                                        <div className="sidebar-title">
                                            <h3>Categories</h3>
                                        </div>
                                        <ul className="categories clearfix">
                                            <li><Link to={'/#'}>Furnitures</Link></li>
                                            <li><Link to={'/#'}>Wall Posters</Link></li>
                                            <li><Link to={'/#'}>Kitchen</Link></li>
                                            <li><Link to={'/#'}>Living Room</Link></li>
                                        </ul>
                                    </div>
                                    {/* <!--End single sidebar--> */}
                                    {/* <!--Start single sidebar-->  */}
                                    <div className="single-sidebar-box">
                                        <div className="sidebar-title">
                                            <h3>Filter by Price</h3>
                                        </div>
                                        <div className="price-ranger">
                                            {/* <div className="ranger-min-max-block">
                                                <span>Price:</span>
                                                <input type="text" readonly className="min" />
                                                <span>-</span>
                                                <input type="text" readonly className="max" />
                                                <input type="submit" value="Filter By" />
                                            </div> */}
                                        </div>
                                    </div>
                                    {/* <!--End single sidebar--> */}
                                    {/* <!--Start single sidebar--> */}
                                    <div className="single-sidebar-box">
                                        <div className="sidebar-title">
                                            <h3>Popular Products</h3>
                                        </div>
                                        <ul className="products-post">
                                            <li>
                                                <div className="img-holder">
                                                    <img src={require('../../assets/images/shop/popular-product-1.jpg')} alt="Awesome " />
                                                    <div className="overlay-style-one">
                                                        <div className="box">
                                                            <div className="content">
                                                                <Link to={'/#'}><span className="fa fa-link"></span></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="title-holder">
                                                    <h5 className="post-title"><Link to={'/#'}>Office Chair</Link></h5>
                                                    <span>$34.99</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="img-holder">
                                                    <img src={require('../../assets/images/shop/popular-product-2.jpg')} alt="Awesome " />
                                                    <div className="overlay-style-one">
                                                        <div className="box">
                                                            <div className="content">
                                                                <Link to={'/#'}><span className="fa fa-link"></span></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="title-holder">
                                                    <h5 className="post-title"><Link to={'/#'}>Garden Chair</Link></h5>
                                                    <span>$29.00</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="img-holder">
                                                    <img src={require('../../assets/images/shop/popular-product-3.jpg')} alt="Awesome " />
                                                    <div className="overlay-style-one">
                                                        <div className="box">
                                                            <div className="content">
                                                                <Link to={'/#'}><span className="fa fa-link"></span></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="title-holder">
                                                    <h5 className="post-title"><Link to={'/#'}>Study Lamp</Link></h5>
                                                    <span>$24.99</span>
                                                </div>
                                            </li>

                                        </ul>
                                    </div>
                                    {/* <!--End single sidebar--> */}
                                    {/* <!--Start single sidebar-->  */}
                                    <div className="single-sidebar-box-two">
                                        <div className="add-banner-widget" style={{ backgroundImage: "url(" + addbg + ")" }}>
                                            <div className="content">
                                                <h5>350x600</h5>
                                                <h3>Add Banner</h3>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!--End single sidebar--> */}
                                </div>
                            </div>
                            {/* <!--End Sidebar Wrapper--> */}

                        </div>
                    </div>
                </section>
                {/* <!--End Shop Section--> */}



                <Footer />
            </>
        )
    }
}
export default Shop;