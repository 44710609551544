import React, { Component } from "react";
const banner = require("./../../assets/images/main-slider/banner.png");
class HomeSlider1 extends Component {
  render() {
    return (
      <>
        <img src={banner} alt="Bridgemark Banner" />
      </>
    );
  }
}
export default HomeSlider1;
